import { combineReducers } from 'redux';
import { authentication } from './authentication.reducer';
import { user } from './user.reducer';
import { alert } from './alert.reducer';
import { exam } from './exam.reducer';
const rootReducer = combineReducers({
  authentication,
  user,
  exam,
  alert
});
export default rootReducer;