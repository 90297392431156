import React, {useState} from 'react';
import { navigation } from '_data/navigation';
import { useHistory } from 'react-router-dom';
import "./sidebar.css";
import { messageService } from "_services";
import { msgType } from '_constants';
import { returnDefaultValueObj } from '_services';
import { Link,useLocation } from 'react-router-dom';
export const Sidebar = (props: any) => {
	const localtionArray=useLocation().pathname.split('/').filter((data:any)=>data!=='');
	const history = useHistory();
	const { UserRole, info,authentication } = props;
	const {siteConfig}=props.userInfo;
	const permission=props.userInfo.permissionData?props.userInfo.permissionData:{};
    const [selectedMenu, setSelectedMenu]:any = useState({selectedKey:null,selectedValue:false});
	const defaultValue: any = returnDefaultValueObj(props.userInfo);
	const sidebarData: any = navigation.sidebarOptions;
	const routeToLink = (link: string) => history.push(link);
	let sidebarItems: any = [];
	const checkIsOptionSelected=(option:any)=>{
		if(option.urlCheckLength===0 && localtionArray.length===0){
			return true;
		}else if (option.type === 'link') {
			const optionLinkArray=option.link.split('/').filter((data:any)=>data!=='');
			if(option.urlCheckLength!==0 && localtionArray.length>=optionLinkArray.length){
					let isPerfectMatch=true;
					for(let i=0;i<option.urlCheckLength;i++){
						if(localtionArray[i]!==optionLinkArray[i]){
							isPerfectMatch=false;
						}
					}
					return isPerfectMatch;
			}
		}else if(option.type === 'dropdown' && option.urlCheckLength!==0 && option.checkLinkUrlArray && option.checkLinkUrlArray.length>0){
			let isAnyOneTrue=false;
			option.checkLinkUrlArray.forEach((link:string)=>{
				const optionLinkArray=link.split('/').filter((data:any)=>data!=='');
				if(option.urlCheckLength!==0 && localtionArray.length>=optionLinkArray.length){
					let isPerfectMatch=true;
					for(let i=0;i<option.urlCheckLength;i++){
						if(localtionArray[i]!==optionLinkArray[i]){
							isPerfectMatch=false;
						}
					}
					if(isPerfectMatch===true){
						isAnyOneTrue=true;
					}
				}
			});
			return isAnyOneTrue;
		}
		return false;
	}
	const checkIsChildOptionSelected=(option:any,value:number)=>{
		if(option.urlCheckLength!==0 && localtionArray.length>=(option.urlCheckLength+1)){
			return parseInt(localtionArray[option.urlCheckLength])===value;
		}else{
			return false;
		}
	}
	sidebarData[UserRole].forEach((option: any,index:number) => {
		if(option.permissionKey && permission[option.permissionKey] && permission[option.permissionKey].isView===false){
			return false;
		}
		if (option.type === 'link') {
			const isOptionSelected=checkIsOptionSelected(option);
			sidebarItems.push(
				<li className={"nav-item "+option.class} key={option.key}>
					<Link className={"nav-link " + ((isOptionSelected) ? "active" : "")} to='/' onClick={(e) => { e.preventDefault(); 
								setSelectedMenu({selectedKey:null,selectedValue:true});routeToLink(option.link) }}>
						<span>{option.name}</span>
					</Link>
				</li>
			);
		} else if (option.type === 'dropdown') {
			if (option.state === true) {
				if (info && info[option.key]) {
					let dropdownList: any = []; 
					const isOptionSelected=checkIsOptionSelected(option);
					info[option.key].forEach((data: any) => {
						const checkIsChildSelected=checkIsChildOptionSelected(option,data[option.index[0]]);
						dropdownList.push(
							<Link className={"dropdown-item "+option.class+" "+ ((isOptionSelected && checkIsChildSelected) ? "active" : "")} key={data[option.index[0]]} to="/" onClick={(e) => {
								e.preventDefault();
								setSelectedMenu({selectedKey:null,selectedValue:true});
								if (option.key === 'subjects') {
									defaultValue.defaultSubject = data;
									messageService.sendMessage({ type: msgType.setDefaultValue, data: defaultValue });
								} else if (option.key === 'classes') {
									defaultValue.defaultClass = data;
									messageService.sendMessage({ type: msgType.setDefaultValue, data: defaultValue });
								} 
								routeToLink(option.link.replace('{id}', data[option.index[0]]));
							}}>
								{data[option.index[1]]}
							</Link>
						);
					});
					sidebarItems.push(
						<li className={`nav-item dropdown ${option.class} `+(((isOptionSelected===true && selectedMenu.selectedKey===null) || (selectedMenu.selectedKey===index && selectedMenu.selectedValue===true))?'open':'')} key={option.name}>
							<Link
								className="nav-link dropdown-toggle "
								to="/"
								onClick={(e) => { 
									e.preventDefault(); 
									if(selectedMenu.selectedKey===index){
										setSelectedMenu({selectedKey:index,selectedValue:!selectedMenu.selectedValue});
									}else{
										setSelectedMenu({selectedKey:index,selectedValue:true});
									}
								}}
								id="pagesDropdown"
								role="button"
								aria-haspopup="true"
								aria-expanded="true"
							>
								<span>{option.name}</span>
								<i className="pi pi-ellipsis-h close"></i>
								<i className="pi pi-ellipsis-v open"></i>
							</Link>
							<div className="dropdown-menu" aria-labelledby="pagesDropdown">
								{dropdownList}
							</div>
						</li>
					);
				}
			}
		}
	});
	return (
		<div id="sidebar-wrapper" style={{ "width": "15%" }}>
			<div className="logo">
				<Link onClick={() => routeToLink('/')} to="/">
					<img src={siteConfig && siteConfig.schoolImage?siteConfig.schoolImage:'/assets/images/logo.png'} width="100%"  alt={siteConfig && siteConfig.schoolName?siteConfig.schoolName:"No Image"}  height="80" />
				</Link>
			</div>

			<ul className="sidebar navbar-nav">{sidebarItems}</ul>
		</div>
	);
};
