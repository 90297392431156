import axios from "axios";
import { services } from "_enums";


export const assignPracticeToStudemt = (data: any) => {
    return axios.post(`${services.assignPracticeToStudent}`, data);
}




