import React, { useState, Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { overviewHeaderConfig } from "./config";
import { createRedirectUrlWithParams, getImageFromGCByURL } from '_services';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
export const OverviewHeader = (props: any) => {
    const { Fullname, subjectName, subjectIcon, subjectId, HeaderImage, studentUUId, studentId, currentView, config, studentName, customHeaderText } = props;
    const history = useHistory();
    const {  user }: any = useSelector(state => state);
	const permission=user.permissionData?user.permissionData:{};
    const [showDropdown, setDropdown] = useState(false);
    const [headerImage, setHeaderImage] = useState([]);
    const image = "/assets/images/" + subjectIcon;
    const checkValueAndReturnClass = (config: any) => {
        if (config.check === true && ((typeof config.checkValue === 'string' && props[config.checkByName] === config.checkValue) || (typeof config.checkValue === 'object' && config.checkValue.includes(props[config.checkByName])))) {
            return config.addClassAfterCheck;
        }
        return '';
    }
    const createRedirectUrl = (config: any) => {
        return createRedirectUrlWithParams({ url: config.redirectUrl, params: config.redirectParams, paramValues: [] }, props);
    }
    useEffect(() => {
        if (overviewHeaderConfig[config].addImageSectionInHeader && HeaderImage) {
            getImageFromGCByURL(HeaderImage).then((data: string) => {
                const array: any = [];
                array.push(<img className="thumbnial-image-round" alt="No Image" src={data} />);
                setHeaderImage(array);
            });
        }
    }, [HeaderImage]);
    return (<div className="admin_panel">
        {subjectName != '' ? <div className="container-fluid pt-25">
            <div className="mathas_header">
                <div className="heading_pages">
                    <h2><b>{Fullname}</b> you are in
                        {!overviewHeaderConfig[config].isCustomContent ? <Fragment><b> {subjectName} {/* {overviewHeaderConfig[config].isClass ? 'class' : 'subject'} */}  {Object.keys(overviewHeaderConfig[config]).map((data: any) => {
                            if (overviewHeaderConfig[config][data].label && checkValueAndReturnClass(overviewHeaderConfig[config][data])) {
                                return overviewHeaderConfig[config][data].label;
                            }
                        })}</b></Fragment> : <Fragment><b> {subjectName}</b></Fragment>} </h2>
                </div>
                {/* <div className="mathas_img">
                    <Link to='/' >
                        <img src="/assets/images/home.png" alt='icon' />
                    </Link>
                </div> */}
            </div>
        </div> : ''}
        <div className="container-fluid pt-50">
            {overviewHeaderConfig[config].addImageSectionInHeader ? <div className="header-image" style={{ width: "10%" }}>{headerImage}<p className="textCenter">{studentName}</p></div> : ''}
            <div className={`navbar_main ` + (overviewHeaderConfig[config].addImageSectionInHeader ? 'panelWithImage' : '')}>
                <div className="navbar navbar-default " role="navigation">
                    <div className="">
                        <div className="collapse navbar-collapse navbar-ex1-collapse">
                            <ul className="nav navbar-nav navigation_menu">
                                {overviewHeaderConfig[config].overview && overviewHeaderConfig[config].overview.show === true ? <li className={checkValueAndReturnClass(overviewHeaderConfig[config].overview)}>
                                    <Link to={createRedirectUrl(overviewHeaderConfig[config].overview)}>Overview</Link>
                                </li> : ''}
                                {overviewHeaderConfig[config].skillImpact && overviewHeaderConfig[config].skillImpact.show === true ? <li className={(showDropdown) ? "open" : '' || checkValueAndReturnClass(overviewHeaderConfig[config].skillImpact)}>
                                    <Link to="" className="subjectChildHeaderOption" onClick={(e) => { e.preventDefault(); setDropdown(!showDropdown) }}>Skill Impact
                                        <b className="caret"></b>
                                    </Link>
                                    <ul className="dropdown-menu">
                                        {overviewHeaderConfig[config].intraSubject && overviewHeaderConfig[config].intraSubject.show === true ? <li className={checkValueAndReturnClass(overviewHeaderConfig[config].intraSubject) + " menu-item subjectChildHeaderOption"} >
                                            <Link to={createRedirectUrl(overviewHeaderConfig[config].intraSubject)} className="subjectChildHeaderOption">Intra-Subject</Link>
                                        </li> : ''}
                                        {overviewHeaderConfig[config].interSubject && overviewHeaderConfig[config].interSubject.show === true ? <li className={checkValueAndReturnClass(overviewHeaderConfig[config].interSubject) + " menu-item subjectChildHeaderOption"}>
                                            <Link to={createRedirectUrl(overviewHeaderConfig[config].interSubject)} className="subjectChildHeaderOption">Inter-Subject</Link>
                                        </li> : ''}
                                    </ul>
                                </li> : ''}
                                {overviewHeaderConfig[config].classProfile && overviewHeaderConfig[config].classProfile.show === true ? <li className={checkValueAndReturnClass(overviewHeaderConfig[config].classProfile)}>
                                    <Link to={createRedirectUrl(overviewHeaderConfig[config].classProfile)}>Class Profile</Link>
                                </li> : ''}
                                {overviewHeaderConfig[config].content && overviewHeaderConfig[config].content.show === true && (!permission[overviewHeaderConfig[config].content.permissionKey] || (permission[overviewHeaderConfig[config].content.permissionKey] && permission[overviewHeaderConfig[config].content.permissionKey].isView !== false)) ? <li className={checkValueAndReturnClass(overviewHeaderConfig[config].content)}>
                                    <Link to={createRedirectUrl(overviewHeaderConfig[config].content)}>Content</Link>
                                </li> : ''}
                                {overviewHeaderConfig[config].personalisedPractice && overviewHeaderConfig[config].personalisedPractice.show === true && (!permission[overviewHeaderConfig[config].personalisedPractice.permissionKey] || (permission[overviewHeaderConfig[config].personalisedPractice.permissionKey] && permission[overviewHeaderConfig[config].personalisedPractice.permissionKey].isView !== false)) ? <li className={checkValueAndReturnClass(overviewHeaderConfig[config].personalisedPractice)}>
                                    <Link to={createRedirectUrl(overviewHeaderConfig[config].personalisedPractice)}>Personalised Practice</Link>
                                </li> : ''}
                                {overviewHeaderConfig[config].assignment && overviewHeaderConfig[config].assignment.show === true && (!permission[overviewHeaderConfig[config].assignment.permissionKey] || (permission[overviewHeaderConfig[config].assignment.permissionKey] && permission[overviewHeaderConfig[config].assignment.permissionKey].isView !== false)) ? <li className={checkValueAndReturnClass(overviewHeaderConfig[config].assignment) + " " + overviewHeaderConfig[config].assignment.defaultClass}>
                                    <Link to={createRedirectUrl(overviewHeaderConfig[config].assignment)}>{overviewHeaderConfig[config].assignment.label ? overviewHeaderConfig[config].assignment.label : 'Assignment'}</Link>
                                </li> : ''}
                                {overviewHeaderConfig[config].customText && overviewHeaderConfig[config].customText.show === true ? <li className={checkValueAndReturnClass(overviewHeaderConfig[config].customText)}>
                                    <Link to="" className="font-22" onClick={(e) => { e.preventDefault(); }}>{customHeaderText}</Link>
                                </li> : ''}
                            </ul>
                            <ul className="nav navbar-nav navbar-right">
                                {overviewHeaderConfig[config].goToPrevious && overviewHeaderConfig[config].goToPrevious.show === true ? <li className="nav-item">
                                    <Link to="" onClick={(e) => { e.preventDefault(); history.goBack(); }} className="font-22 overViewHeaderPrevious">
                                        Go to previous
                                    </Link>
                                </li> : ''}
                                {/* {overviewHeaderConfig[config].icon && overviewHeaderConfig[config].icon.show === true ? <li className="nav-item">
                                    <Link to={createRedirectUrl(overviewHeaderConfig[config].icon)} className="nav-link">
                                        <img src={image}  alt="No Image"  />
                                    </Link>
                                </li> : ''} */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>);
}


