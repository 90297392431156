import { messageService, getServiceByUrlParam } from '_services';
import { serviceParams, services } from "_enums";
import { authHeader, errorHandler } from "_helpers";
import { msgType, roleConstants } from '_constants';
import axios from 'axios';
import { env } from 'environment';
var isSettingFetched:any=null;
//function to map default value in redux by role
export const mapDefaultValueInRedux = (props: any) => {
	messageService.getMessage().subscribe(async(res: any) => {
		if (res.type === msgType.setDefaultValue) {
			props['setDefaultValues'](res.data);
			if(res.data && res.data.defaultClass && res.data.defaultClass && res.data.defaultClass.standardId  && res.data.defaultSubject.subjectId){
				let mergeValue=res.data.defaultClass.classId+'_'+res.data.defaultSubject.subjectId;
				if(mergeValue!==isSettingFetched){
					isSettingFetched=mergeValue;
					let permissionData: any = {};
					let siteConfig: any = {};
					const settingRes = await axios.get(`${env.serviceBaseUrl + services.permission}?classId=${res.data.defaultClass.classId}&subjectId=${res.data.defaultSubject.subjectId}`, authHeader());
					if (settingRes.status === 200 && settingRes.data) {
						if (settingRes.data.permission && Array.isArray(settingRes.data.permission) && settingRes.data.permission.length > 0) {
							settingRes.data.permission.forEach((data: any) => {
								permissionData[data.component] = data.permissions;
							});
						}
						if (settingRes.data.config) {
							siteConfig = settingRes.data.config;
						}

					}
					props.setPermission({permissionData:permissionData,siteConfig:siteConfig});
				}
			}
		} else if (res.type === msgType.setDefaultStudentByRole) {
			if (res.data.role === roleConstants.PARENT || res.data.role === roleConstants.STUDENT) {
				if (Object.keys(res.data.defaultValue.defaultStudent).length > 0) {
					res.data.defaultValue.defaultSubject = res.data.defaultValue.defaultStudent.subjects.filter((subject: any) => subject.subjectId === parseInt(res.data.selectedSubjectId))[0];
					props['setDefaultValues'](res.data.defaultValue);
				}
			}
		} else if (res.type === msgType.setDefaultStudentById) {
			getServiceByUrlParam({ serviceName: "studentInfo", UserUUID: res.data.studentUUId }).then(student => {
				res.data.defaultValue.defaultStudent = student;
				props['setDefaultValues'](res.data.defaultValue);
			});
		}
	});
}