export enum studentService {
    studentInfo = "focus-mvp-1/students/{UserUUID}?studentUUID={UserUUID}",
    getStudentSubjectSkills = "focus-mvp-1/students/details?studentId={studentId}&subjectId={subjectId}&classId={classId}",
    getStudentSubjectSubSkills = "focus-mvp-1/students/details?studentId={studentId}&subjectId={subjectId}&classId={classId}&skillId={skillId}",
    getStudentdSubjectAssignments = "focus-mvp-1/assignments/{classId}?classId={classId}&filter={filter}&size={size}&sort={sort}&subjectId={subjectId}&studentId={studentId}",
}

export const studentServiceParams = {
    studentInfo: ["UserUUID"],
    getStudentSubjectSkills: ["studentId", "subjectId", "classId"],
    getStudentSubjectSubSkills: ["studentId", "subjectId", "classId", "skillId"],
    getStudentdSubjectAssignments: ["classId", "filter", "size", "sort", "subjectId", "studentId"],
}