export enum services {
    login = "login",
    forgotPassword = 'forgotPassword',
    permission = 'focus-mvp-1/general/settings',
    crudRemark = 'focus-mvp-1/planner/remark',
    createPassword = 'createPassword',
    uploadExamMarksheet = 'focus-mvp-1/upload/marks?examId=',
    verifyUser = 'verify',
    staffInfo = "staff/profile",
    getCalendarEvent = "focus-mvp-1/calendar/events?classId={classId}&month={month}&year={year}",
    getAllCalendarEvent = "focus-mvp-1/calendar/events?month={month}&year={year}",
    teacherPlanner = "focus-mvp-1/planner?subjectId={subjectId}&classId={classId}&month={month}&year={year}",
    teacherPlannerMonth = "focus-mvp-1/planner/calendar?subjectId={subjectId}&classId={classId}",
    getAllUpcomingAssignment = "focus-mvp-1/assignments/{classId}?classId={classId}&filter={filter}&size={size}&sort={sort}",
    getInterSubjectSkills = "focus-mvp-1/students/details/inter?studentId={studentId}&classId={classId}&subjectId={subjectId}&impactedSubjectId={impactedSubjectId}",
    getInterImpactedSubjectSkills = "focus-mvp-1/students/details/inter?studentId={studentId}&classId={classId}&subjectId={subjectId}&impactedSubjectId={impactedSubjectId}&impactedSkillId={impactedSkillId}&skillId={skillId}",
    getInterClassSubjectSkills = "focus-mvp-1/class/details/inter?studentId={studentId}&classId={classId}&subjectId={subjectId}&impactedSubjectId={impactedSubjectId}",
    getInterClassImpactedSubjectSkills = "focus-mvp-1/class/details/inter?studentId={studentId}&classId={classId}&subjectId={subjectId}&impactedSubjectId={impactedSubjectId}&impactedSkillId={impactedSkillId}&skillId={skillId}",
    studentInfo = "focus-mvp-1/students/info",
    teachersInfo = "focus-mvp-1/teachers/info",
    studentProfile = "student/profile",
    studentByUUID = "focus-mvp-1/students",
    getStudentSubjectSkills = "focus-mvp-1/students/details?studentId={studentId}&subjectId={subjectId}&classId={classId}",
    getStudentSubjectSubSkills = "focus-mvp-1/students/details?studentId={studentId}&subjectId={subjectId}&classId={classId}&skillId={skillId}",
    getStudentSubjectSubSkillsBySubSkillId = "focus-mvp-1/students/details?studentId={studentId}&subjectId={subjectId}&classId={classId}&skillId={skillId}&subSkillId={subSkillId}",
    getStudentdSubjectAssignments = "focus-mvp-1/assignments/{classId}?classId={classId}&filter={filter}&size={size}&sort={sort}&subjectId={subjectId}&studentId={studentId}",
    parentInfo = "focus-mvp-1/parents/info",
    parentProfile = "parent/profile",
    classEvaluation = 'focus-mvp-1/class/practice/evaluation',
    getParentSubjectSkill = "focus-mvp-1/skills/{subjectId}?studentId={studentId}",
    getParentSubjectAssignments = "focus-mvp-1/assignments/{classId}?classId={classId}&filter={filter}&size={size}&sort={sort}&subjectId={subjectId}",
    getParentClassDetails = "focus-mvp-1/class/details?subjectId={subjectId}&classId={classId}",
    getParentStudentDetails = "focus-mvp-1/students/details?studentId={studentId}&classId={classId}&subjectId={subjectId}",
    teacherInfo = "focus-mvp-1/teachers/{UserUUID}?",
    getSubjectAssignments = "focus-mvp-1/assignments/{classId}?classId={classId}&subjectId={subjectId}&filter={filter}&size={size}&sort={sort}",
    teacherAssignments = "focus-mvp-1/assignments/{classId}?&subjectId={subjectId}&sort={sort}&size={size}",
    assignmentMarks = "focus-mvp-1/assessment/evaluation/{assignmentId}?",
    getTeacherSkills = "focus-mvp-1/subjects/{subjectId}/allSkills?",
    getExams = "focus-mvp-1/exams/all?&subjectId={subjectId}&classId={classId}",
    addExams = "focus-mvp-1/exams?",
    notificationExam = "focus-mvp-1/notification/exam",
    getAddExaminationQues = "focus-mvp-1/exams/{exam_id}/details?",
    getAssessmentLink = "focus-mvp-1/assessment/link?examId={examId}",
    getTeacherStudents = "focus-mvp-1/students/?classId={classId}",
    getTeacherAssignment = "focus-mvp-1/assignments/{classId}?classId={classId}&filter={filter}&size={size}&sort={sort}&subjectId={subjectId}",
    principalInfo = "focus-mvp-1/principal/info",
    getPrincipalClassDetails = "focus-mvp-1/principal/details?&standardId{standardId}",
    studentSubjectSkill = "focus-mvp-1/students/details?&studentId={studentId}&classId={classId}&subjectId={subjectId}",
    parentSubjectSkill = "focus-mvp-1/students/details?studentId={studentId}&classId={classId}&subjectId={subjectId}",
    getparentSubjectSubSkill = "focus-mvp-1/students/details?studentId={studentId}&classId={classId}&subjectId={subjectId}&skillId={skillId}",
    parentSubjectSubSKillBySubSkill = "focus-mvp-1/students/details?studentId={studentId}&subjectId={subjectId}&classId={classId}&skillId={skillId}&subSkillId={subSkillId}",
    teacherSubjectSkill = "focus-mvp-1/class/details?&classId={classId}&subjectId={subjectId}",
    teacherStudentSubjectSkill = 'focus-mvp-1/students/details?studentId={studentId}&classId={classId}&subjectId={subjectId}',
    teacherStudentSubjectSubSkill = 'focus-mvp-1/students/details?studentId={studentId}&classId={classId}&subjectId={subjectId}&skillId={skillId}',
    teacherStudentSubjectSubSkillBySubSkill = 'focus-mvp-1/students/details?studentId={studentId}&classId={classId}&subjectId={subjectId}&skillId={skillId}&subSkillId={subSkillId}',
    teacherClassSubjectSkill = 'focus-mvp-1/class/details?studentId={studentId}&classId={classId}&subjectId={subjectId}',
    teacherClassSubjectSubSkill = 'focus-mvp-1/class/details?studentId={studentId}&classId={classId}&subjectId={subjectId}&skillId={skillId}',
    teacherClassSubjectSubSKillBySubSkill = 'focus-mvp-1/class/details?studentId=NaN&classId={classId}&subjectId={subjectId}&skillId={skillId}&subSkillId={subSkillId}',
    allAssigments = "focus-mvp-1/assignments",
    studentListByClass = "focus-mvp-1/students?classId={classId}",
    addAssignment = "focus-mvp-1/assignments?",
    assignPracticeToStudent = "focus-mvp-1/classContent?",
    getClassDetailForSkillSubSkill = "focus-mvp-1/class/details/all?&classId={classId}&subjectId={subjectId}",
    getClassDetailForSkillSubSkillWithSkillId = "focus-mvp-1/class/details/all?&classId={classId}&subjectId={subjectId}&skillId={skillId}",
    getStudentDetailForSkillSubSkill = "focus-mvp-1/student/details/all?&classId={classId}&studentId={studentId}&subjectId={subjectId}",
    getStudentDetailForSkillSubSkillWithSkillId = "focus-mvp-1/student/details/all?&classId={classId}&studentId={studentId}&subjectId={subjectId}&skillId={skillId}",
    getStudentContentForSkillSubSkill = "focus-mvp-1/content?&skillId={skillId}&subSkillId={subSkillId}",
    getStudentContentProgress = "focus-mvp-1/student/content/progress?&studentId={studentId}&classId={classId}&contentId={contentId}",
    saveStudentContentNotes = "focus-mvp-1/student/content/notes?",
    saveStudentContentFlag = "focus-mvp-1/student/content/progress?&studentId={studentId}&classId={classId}&contentId={contentId}&completeFlag={completeFlag}",
    getTeacherContentProgress = "focus-mvp-1/teacher/notes?&teacherId={teacherId}&classId={classId}&contentId={contentId}",
    saveTeacherContentNotes = "focus-mvp-1/teacher/notes",
    startSessionUrl = "focus-mvp-1/student/practice/session?",
    getSubjectQuestion = "focus-mvp-1/practice/question?&studentId={studentId}&classId={classId}&sessionId={sessionId}&subSkillId={subSkillId}",
    submitAnswer = "focus-mvp-1/practice/question/verify?",
    endPersonaliseSessionUrl = "focus-mvp-1/student/personalise/session?",
    addPersonaliseQuestionNote = "focus-mvp-1/personalise/question/note?",
    startPersonaliseSessionUrl = "focus-mvp-1/student/personalise/session?",
    getPersonaliseSubjectQuestion = "focus-mvp-1/personalise/question?&studentId={studentId}&classId={classId}&sessionId={sessionId}&subSkillId={subSkillId}",
    submitPersonaliseAnswer = "focus-mvp-1/personalise/question/verify?",
    endSessionUrl = "focus-mvp-1/student/practice/session?",
    addQuestionNote = "focus-mvp-1/practice/question/note?",
    addTeacherQuestionNote = "focus-mvp-1/teacher/notes?",
    saveFlag = "focus-mvp-1/practice/question/flag?",
    savePersonaliseFlag = "focus-mvp-1/personalise/question/flag?",
    getStudentPracticeSkillSubSkill = "focus-mvp-1/student/practice?&studentId={studentId}&classId={classId}&subjectId={subjectId}",
    getStudentPersonalisePracticeSkillSubSkill = "focus-mvp-1/student/personalise?&studentId={studentId}&classId={classId}&subjectId={subjectId}",
    getStudentPracticeList = "focus-mvp-1/practice/review?&studentId={studentId}&classId={classId}&subSkillId={subSkillId}",
    getStudentPersonalisePracticeList = "focus-mvp-1/personalise/review?&studentId={studentId}&classId={classId}&subSkillId={subSkillId}",
    getClassPersonalisePracticeList = "focus-mvp-1/class/personalise/review?&teacherId={teacherId}&classId={classId}&subSkillId={subSkillId}",
    getPersonalisePracticeQuestion = "focus-mvp-1/personalise/review/question?&studentId={studentId}&classId={classId}&subSkillId={subSkillId}&quesOrder={quesOrder}",
    getClassPersonalisePracticeQuestion = "focus-mvp-1/class/personalise/question?&teacherId={teacherId}&classId={classId}&subSkillId={subSkillId}&quesOrder={quesOrder}",
    getClassPersonalisePracticeSkillSubSkill = "focus-mvp-1/class/personalise?&classId={classId}&subjectId={subjectId}",

    getClassPracticeList = "focus-mvp-1/class/practice/review?&teacherId={teacherId}&classId={classId}&subSkillId={subSkillId}",
    getPracticeQuestion = "focus-mvp-1/practice/review/question?&studentId={studentId}&classId={classId}&subSkillId={subSkillId}&quesOrder={quesOrder}",
    getClassPracticeQuestion = "focus-mvp-1/class/practice/question?&teacherId={teacherId}&classId={classId}&subSkillId={subSkillId}&quesOrder={quesOrder}",
    getClassPracticeSkillSubSkill = "focus-mvp-1/class/practice?&classId={classId}&subjectId={subjectId}",
    getImpactedSubjectList = "focus-mvp-1/subject/impact?subjectId={subjectId}&classId={classId}",

    getSuperAdminSubjectList = "subjects/all",
    getPersonaliseQuestionCount = "focus-mvp-1/personalise/question/count?subjectId={subjectId}",
    getPersonaliseQuestionContent = "focus-mvp-1/personalise/question/content?subjectId={subjectId}&size={size}&page={page}"

}
export const serviceParams = {
    getAssessmentLink: ["examId"],
    getCalendarEvent: ["classId", "month", "year"],
    teacherPlanner: ["classId", "subjectId", "month", "year"],
    teacherPlannerMonth: ["classId", "subjectId"],
    getImpactedSubjectList: ["classId", "subjectId"],
    getClassDetailForSkillSubSkill: ["classId", "subjectId"],
    getStudentDetailForSkillSubSkill: ["studentId", "subjectId", "classId"],
    getAllUpcomingAssignment: ["classId", "filter", "size", "sort"],
    getInterSubjectSkills: ["studentId", "classId", "subjectId", "impactedSubjectId"],
    getInterClassSubjectSkills: ["studentId", "classId", "subjectId", "impactedSubjectId"],
    getInterImpactedSubjectSkills: ["studentId", "classId", "subjectId", "impactedSubjectId", "impactedSkillId", "skillId"],
    getInterClassImpactedSubjectSkills: ["studentId", "classId", "subjectId", "impactedSubjectId", "impactedSkillId", "skillId"],
    studentInfo: ["uuid"],
    getStudentSubjectSkills: ["studentId", "subjectId", "classId"],
    getStudentSubjectSubSkills: ["studentId", "subjectId", "classId", "skillId"],
    getStudentSubjectSubSkillsBySubSkillId: ["studentId", "subjectId", "classId", "skillId", "subSkillId"],
    getStudentdSubjectAssignments: ["classId", "filter", "size", "sort", "subjectId", "studentId"],
    parentInfo: ["UserUUID"],
    getParentSubjectSkill: ["subjectId", "studentId"],
    getParentSubjectAssignments: ["classId", "filter", "size", "sort", "subjectId"],
    getParentClassDetails: ["subjectId", "classId"],
    getParentStudentDetails: ["studentId", "classId", "subjectId"],
    teacherInfo: ["UserUUID"],
    getSubjectAssignments: ["classId", "subjectId", "filter", "size", "sort"],
    teacherAssignments: ["classId", "subjectId", "sort", "size"],
    assignmentMarks: ["assignmentId"],
    getTeacherSkills: ["subjectId"],
    getExams: ["subjectId", "classId"],
    getTeacherStudents: ["classId"],
    getTeacherAssignment: ["classId", "filter", "size", "sort", "subjectid"],
    principalInfo: ["UserUUID"],
    getPrincipalClassDetails: ["standardId"],
    studentSubjectSkill: ["studentId", "classId", "subjectId"],
    parentSubjectSkill: ["subjectId", "studentId", "classId"],
    parentSubjectSubSKillBySubSkill: ["subjectId", "studentId", "classId", "skillId", "subSkillId"],
    teacherStudentSubjectSkill: ["subjectId", "studentId", "classId"],
    teacherClassSubjectSkill: ["subjectId", "studentId", "classId"],
    teacherClassSubjectSubSkill: ["subjectId", "studentId", "classId", "skillId"],
    teacherClassSubjectSubSKillBySubSkill: ["subjectId", "studentId", "classId", "skillId", "subSkillId"],
    teacherStudentSubjectSubSkill: ["subjectId", "studentId", "classId", "skillId"],
    teacherStudentSubjectSubSkillBySubSkill: ["subjectId", "studentId", "classId", "skillId", "subSkillId"],
    getparentSubjectSubSkill: ["subjectId", "studentId", "classId", 'skillId'],
    teacherSubjectSkill: ["classId", "subjectId"],
    studentListByClass: ["classId"],
    addExaminationQues: ["exam_id"],
    getPersonaliseQuestionCount: ["subjectId"]
}