import React, { useEffect, useState } from 'react';
import { navigation } from '_data/navigation';
import "./header.css";
import { messageService, getImageFromGCByURL } from "_services";
import { msgType } from '_constants';
import { useHistory } from 'react-router-dom';
import { returnDefaultValueObj } from '_services';
import { Link } from 'react-router-dom';
import { roleConstants } from '../../_constants/role.constants';
//var isDefaultSet=false;
export const Header = (props: any) => {
   const { UserRole } = props.user;
   const [headerImage, setHeaderImage] = useState([]);
   const { info } = props;
   const Fullname = info.name;
   const UserImage = info.image;
   let defaultValue: any = returnDefaultValueObj(props.userInfo);
   const headerData: any = navigation.headerOptions;
   let headerItem: any = [];
   const history = useHistory();
   const routeToLink = (link: string) => history.push(link);
   useEffect(() => {
      if (UserImage) {
         getImageFromGCByURL(UserImage).then((data: string) => {
            const array: any = [];
            array.push(<img className="thumbnial-image-round" key="user-image" alt="No Image" src={data} />);
            setHeaderImage(array);
         });
      }
   }, [info.image]);
   const createHeaderItem = (info: any, option: any) => {
      if (info && info[option.key]) {
         let defaultName = '', dropdownList: any = [];
         /* if(!isDefaultSet){
            messageService.sendMessage({ type: msgType.setDefaultValue, data: defaultValue });
            isDefaultSet=true;
         } */
         info[option.key].forEach((data: any,index:number) => {
            if (option.index[1] === 'fullName') {
               data.fullName = data.firstName;
            }
            if (option.key === 'children') {
               const lastName = defaultValue.defaultStudent.lastName ? defaultValue.defaultStudent.lastName : '';
               defaultName = defaultValue.defaultStudent.firstName + ' ' + lastName;
               dropdownList.push(
                  <Link to="/" key={data[option.index[0]]} onClick={(e) => { e.preventDefault(); defaultValue.defaultStudent = data; messageService.sendMessage({ type: msgType.setDefaultValue, data: defaultValue }); }}>{data[option.index[1]]}</Link>
               );
            } else if (option.key === 'classes') {
               defaultName = defaultValue.defaultClass.classCode ? defaultValue.defaultClass.classCode : defaultValue.defaultClass.standardCode;
               dropdownList.push(
                  <Link to="/" key={data[option.index[0]]} onClick={(e) => {
                     e.preventDefault();
                     const defaultSubjectPresent = data.subjects.filter((data: any) => data.subjectId === defaultValue.defaultSubject.subjectId);
                     if (defaultSubjectPresent.length === 0) {
                        defaultValue.defaultSubject = data.subjects[0];
                     }
                     defaultValue.defaultClass = data;
                     if (UserRole === roleConstants.TEACHER && window.location.pathname.includes('teacher/student') && !window.location.pathname.includes('teacher/students')) {
                        routeToLink('/teacher/students');
                     }
                     messageService.sendMessage({ type: msgType.setDefaultValue, data: defaultValue });
                  }}>{data[option.index[1]]}</Link>
               );
            } else if (option.key === 'subjects') {
               defaultName = defaultValue.defaultSubject.subjectName;
               if (option.index[0]) {
                  dropdownList.push(
                     <Link to="/" key={data[option.index[0]]} onClick={(e) => {
                        e.preventDefault();
                        defaultValue.defaultSubject = data;
                        messageService.sendMessage({ type: msgType.setDefaultValue, data: defaultValue });
                     }}>{data[option.index[1]]}</Link>
                  );
               }
            }
         });
         if (dropdownList.length > 0) {
            headerItem.push(
               <div className="dropdown_right" key={option.key}>
                  <button className="dropbtn">{defaultName}
                     <i className="fa fa-caret-down"></i>
                  </button>
                  <div className="dropdown-content">{dropdownList}</div>
               </div>
            );
         }
      }
   }
   if (headerData[UserRole] && info) {
      headerData[UserRole].forEach((option: any) => {
         if (option.type === 'dropdown') {
            if (option.state === true) {
               createHeaderItem(info, option);
               if (option.depended && option.depended === true) {
                  createHeaderItem(defaultValue.defaultClass, option.dependedOption);
               }
            }
         }
      });
   }
   const logout = () => {
      props.logout()
   }
   return (
      <header className=" bg-white topbar shadow">
         <div className="container-fluid">
            <div className="top_right">
               {headerItem}
               <div className="dropdown_right">
                  <button className="dropbtn">{Fullname}
                     <i className="fa fa-caret-down"></i>
                  </button>
                  <div className="dropdown-content">
                     <Link to="/" onClick={logout}>Logout</Link>
                  </div>
               </div>
               <div className="user-img">
                  {headerImage}
               </div>
            </div>
         </div>
      </header>
   );
};
