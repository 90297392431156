import React, { useEffect, useState } from 'react';
import { getAllAssignment } from '_services';
import { assignmentConstant } from '_constants';
import { Link } from 'react-router-dom';
import { Spinner } from '_common/ui/spinner';
export const AllUpcomingAssignment = (props: any) => {
    const [assigments, setassignments] = useState([])
    const [spinner, setSpinner] = useState(true)
    const { serviceParam } = props;
    const assignment: any = [];
    const li: any = [];
    const defaultparams = {
        filter: "upcoming",
        size: assignmentConstant.minPageCount,
        sort: "SubmissionDate"
    }
    const params = {
        ...defaultparams, ...serviceParam
    }
    useEffect(() => {
        getAllAssignment(params).then(res => {
            setSpinner(false);
            setassignments(res);
        });
    }, [params.classId, params.subjectId, params.studentID]);
    assigments.forEach((assign: any, index) => {
        let classname = '';
        if (index === 0) {
            classname = "active";
        }
        li.push(<li data-target="#DemoCarousel" data-slide-to={index} className={classname} key={assign.assignmentId}></li>);
        assignment.push(

            <div className={"item " + classname} key={assign.assignmentId}>
                <h2>{assign.subjectName}</h2>
                <div className="carousel-caption">
                    <p>Assignment :</p>
                    <p>{assign.skillName}</p>
                    <p>{assign.description}</p>
                </div>
            </div>);
    });
    let assignments =
        <div className="col-md-4">
            <h5>Upcoming Assignments</h5>
            <div className="shadow bg-white box_calendar box-slider">
                <div style={{ position: 'absolute', left: "35%", top: "45%", fontSize: "200%" }}>
                    {spinner?<Spinner />:''}
                </div>
            </div>
        </div>
    if (assignment.length > 0) {
        assignments = <div className="col-md-4">
            <h5>Upcoming Assignments</h5>
            <div className="shadow bg-white box_calendar box-slider">
                <div id="basic" className="textslider">
                    <div className="thumbnail1 assignmentMinHeight" >
                        <div id="DemoCarousel" className="carousel slide" data-interval="2000" data-ride="carousel">
                            <ol className="carousel-indicators">
                                {li}
                            </ol>
                            <div className="carousel-inner" >
                                {assignment}
                            </div>
                        </div>
                    </div>
                    <div className="navcation_point">
                        <Link className="carousel-control left" to="#DemoCarousel" data-slide="prev">
                            <span>Previous</span>
                        </Link>
                        <Link className="carousel-control right" to="#DemoCarousel" data-slide="next">
                            <span>Next</span>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    }
    return assignments;
}
