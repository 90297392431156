import React, { Fragment, useState } from 'react';
import "./login.css";
import { useForm } from "react-hook-form";
import { userActions } from "_actions";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { authForgotPassword } from '_services';
import { SpinnerService } from '_services/spinner.service';
const Login = (props: any) => {
	const { register, handleSubmit, errors } = useForm();
    const [isForgetPassword, setForgetPassword]: any = useState(false);
	const onSubmit = (data: any) => {
		props.login(data.username, data.password)
	}
	const onForgotSubmit= (data: any) => {
		SpinnerService.sendMessage(true);
		authForgotPassword(data.username).then(data=>{
			setForgetPassword(false);
			SpinnerService.sendMessage(false);
		}).catch((errors) => {
			SpinnerService.sendMessage(false);
		});
	}
	const { alert }: any = props;
	let content = (<div className="login-body">
		<div className="login-wrap">
			<div className="login-img">
				<img src="/assets/images/login-img.jpg" alt='login' />
			</div>
			<div className="login-form">
				<Link to='/create-password' style={{position: "absolute",top: "5px",right: '10px'}} >Create Password</Link>
				<div className="head_login">
					<img src="/assets/images/login-logo.png" alt="login logo" />
					{!isForgetPassword?<h4>Welcome back! Please login to your account.</h4>:<h4>Welcome back! Please provide your username.</h4>}
				</div>
				<div className="m-login__container">
					<div className="m-login__signin">
						{alert.message && <div className={`alert ${alert.type}`}>{alert.message}</div>}
						{!isForgetPassword?<form className="form" onSubmit={handleSubmit(onSubmit)}>
							<div className="row">
								<div className="col-md-12">
									<div className="form-body">
										<div className="form-group">
											<input
												id="username"
												type="text"
												autoComplete="off" 
												className="form-control"
												name="username"
												placeholder=" Username"
												ref={register({ required: true })}
											/>
											{errors.username && <span>This field is required</span>}

										</div>
										<div className="form-group">
											<input
												id="password"
												type="password"
												autoComplete="off" 
												className="form-control"
												name="password"
												placeholder=" Password"
												ref={register({ required: true })}
											/>
											{errors.password && <span>This field is required</span>}

										</div>
										<div className="form-group">
											<div className="remember_check">
												<input type="checkbox" name="remember" /> Remember me
											<Link to='/' onClick={e =>{ setForgetPassword(true);e.preventDefault()}}>Forgot Password</Link>
											</div>
										</div>
										<div className="form-group pt-10">
											<button
												type="submit"
												className="btn btn-primary login_btn"
												name="login"
											>
												Login
										</button>
										</div>
									</div>
								</div>
							</div>
						</form>:<form className="form" onSubmit={handleSubmit(onForgotSubmit)}>
							<div className="row">
								<div className="col-md-12">
									<div className="form-body">
										<div className="form-group">
											<input
												id="username"
												type="text"
												className="form-control"
												name="username"
												placeholder="Username"
												ref={register({ required: true })}
											/>
											{errors.username && <span>This field is required</span>}

										</div>
										<div className="form-group">
										<div className="remember_check" style={{display:"flow-root"}}>
											<Link to='/' onClick={e =>{ setForgetPassword(false);e.preventDefault()}}>Back to login</Link>
											</div>
										</div>
										<div className="form-group pt-10">
											<button
												type="submit"
												className="btn btn-primary login_btn"
												name="reset-password"
											>
												Reset Password
										</button>
										</div>
									</div>
								</div>
							</div>
						</form>}
					</div>
				</div>
				<div className="login_footer">
					<Link to='/' onClick={e => e.preventDefault()}>Privacy policy</Link>
				</div>
			</div>
		</div>
	</div>)
	return <Fragment>{content}</Fragment>

};

function mapState(state: any) {
	const { alert } = state;
	return { alert };
}

const actionCreators = {
	login: userActions.login,
	logout: userActions.logout
};

const connectedLoginPage = connect(mapState, actionCreators)(Login);
export { connectedLoginPage as Login };