import { userConstants } from '_constants';
let userLocal: any = localStorage.getItem('userData');
let user = JSON.parse(userLocal);
const initialState = user ? { loggedIn: true, user } : { loggedIn: false, loggingIn: false };

export function authentication(state = initialState, action: any) {
	switch (action.type) {
		case userConstants.LOGIN_REQUEST:
			return {
				loggingIn: true,
				user: action.user,
			};
		case userConstants.LOGIN_SUCCESS:
			return {
				loggedIn: true,
				user: action.user,
			};
		case userConstants.USER_DATA_FOUND:
			return {
				userDataFound: true,
				loggedIn: true,
				user: action.user,
			};
		case userConstants.USER_DATA_NOTFOUND:
			return {
				userDataFound: false,
				loggedIn: false,
				user: {},
			};
		case userConstants.LOGIN_FAILURE:
			return {};
		case userConstants.LOGOUT:
			return { state: {} };
		default:
			return state;
	}
}
