import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import 'index.css';
import { App } from '_app/App';
import { store } from '_helpers';
import * as serviceWorker from 'serviceWorker';
import 'primereact/resources/primereact.min.css';
import "primereact/resources/themes/nova/theme.css"; 
import axios from "axios";
import { LocalStorageService } from "_services";
import { env } from "./environment";
import { roleConstants } from './_constants/role.constants';

// Add a request interceptor
axios.interceptors.request.use(
  (config: any) => {
    if(!config.url.includes(env.authUrl)){
      if (config.url.indexOf("http://") !== 0 && config.url.indexOf("https://") !== 0) {
        config.url=env.serviceBaseUrl+config.url;
      }
        config.headers.Authorization=`bearer ${LocalStorageService.getAccessToken()}`;
    }
    return config;
  },
  error => {
    Promise.reject(error)
  });



//Add a response interceptor

axios.interceptors.response.use((response) => {
  return response
}, function (error) {
  if (error.response && error.response.status === 401) {
    if(!error.response.data || (error.response.data && !error.response.data.code)){
      window.location.href = "/";
    }
  }
  return Promise.reject(error);
});

render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('focusproject-root')
);
serviceWorker.unregister();
