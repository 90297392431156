import { serviceParams, services } from "_enums";
import axios from "axios";
import { authHeader, errorHandler } from "_helpers";

export const getSubjectSkills =
    (serviceName: string, userData: any) => {
        let service: any = services, serviceParam: any = serviceParams;
        let serviceURL = '';
        if (serviceName) {
            serviceURL = service[serviceName];
            if (serviceParam[serviceName] && serviceParam[serviceName].length > 0) {
                serviceParam[serviceName].forEach((param: string) => {
                    serviceURL = serviceURL.split('{' + param + '}').join(userData[param]);
                })
            }
        }
        return axios.get(`${serviceURL}`, authHeader()).then((response) => {
            if (response.data["skills"]) {
                return response.data["skills"]
            } else {
                return response.data
            }
        }).catch((error) => {
            errorHandler(error.response.message, error.response.status);
            return Promise.reject(error)
        })
    }

export const getSubjectSubSkills =
    (serviceName: string, userData: any) => {
        let service: any = services, serviceParam: any = serviceParams;
        let serviceURL = '';
        if (serviceName) {
            serviceURL = service[serviceName];
            if (serviceParam[serviceName] && serviceParam[serviceName].length > 0) {
                serviceParam[serviceName].forEach((param: string) => {
                    serviceURL = serviceURL.split('{' + param + '}').join(userData[param]);
                })
            }
        }
        return axios.get(`${serviceURL}`, authHeader()).then((response) => {
            if (response.data["skills"]) {
                return response.data["skills"]
            } else {
                return response.data
            }
        }).catch((error) => {
            errorHandler(error.response.message, error.response.status);
            return Promise.reject(error)
        })
    }


export const getInterSubjectSkill = (serviceName: any, userData: any) => {
    let service: any = services, serviceParam: any = serviceParams;
    let serviceURL = '';
    if (serviceName) {
        serviceURL = service[serviceName];
        if (serviceParam[serviceName] && serviceParam[serviceName].length > 0) {
            serviceParam[serviceName].forEach((param: string) => {
                serviceURL = serviceURL.split('{' + param + '}').join(userData[param]);
            })
        }
    }
    return axios.get(`${serviceURL}`, { params: userData }).then((response) => {
        return response.data
    }).catch((error) => {
        errorHandler(error.response.message, error.response.status);
        return Promise.reject(error)
    })
}