import { examConstants } from '../_constants';

export function exam(state = {}, action:any) {
  switch (action.type) {
    case examConstants.SET_DATASHEET_RECORD:
      return {
        datasheet: action.data.datasheet
      };
    default:
      return state
  }
}
