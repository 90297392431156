
import { userConstants,roleConstants} from "_constants";
import { serviceParams, services } from "_enums";
import { messageService} from "_services";
import { msgType } from '_constants';
import axios from "axios";
import { authHeader } from "_helpers";
import {env} from '../environment';
//function to create form data for given array 
export const createFormData = (formObj: any) => {
	let formData = new FormData();
	formObj.forEach((data: any) => {
		for (let key in data) {
			formData.append(key, data[key]);
		}
	});
	return formData;
}
//function to check user is logged in
export const checkLoggedInUser = () => {
	const success = (user: any) => {
		localStorage.setItem('userData', JSON.stringify(user));
		return { type: userConstants.USER_DATA_FOUND, user };
	};
	let localData: any = localStorage.getItem('userData');
	const userData = JSON.parse(localData);
	if (userData && userData !== '' && Object.keys(userData).length > 0) {
		return (dispatch: any) => {
			dispatch(success(userData));
		};
	} else {
		return (dispatch: any) => {
			dispatch({ type: userConstants.USER_DATA_NOTFOUND });
		};
	}
}
export const  getParameterValue=(subjectId:number,studentId:number,classId:number,studentUUId:string,UserRole:string,state:any,count=0)=>{
	const defaultValue:any=returnDefaultValueObj(state);
	const values:any={defaultSubject:defaultValue.defaultSubject,defaultStudent:defaultValue.defaultStudent,selectedSubjectId:0,selectedClassId:0,selectedStudentUUId:''};
	const defaultStudentId=(Object.keys(defaultValue.defaultStudent).length>0)?defaultValue.defaultStudent.studentId:'';
	const defaultSubjectId=(Object.keys(defaultValue.defaultSubject).length>0)?defaultValue.defaultSubject.subjectId:'';
	const defaultStudentUUId=(Object.keys(defaultValue.defaultStudent).length>0)?defaultValue.defaultStudent.studentUUId:'';
	const defaultClassId=(Object.keys(defaultValue.defaultClass).length>0)?defaultValue.defaultClass.classId:'';
	subjectId=(subjectId)?subjectId:parseInt(defaultSubjectId);
	studentId=(studentId)?studentId:parseInt(defaultStudentId);
	classId=(classId)?classId:parseInt(defaultClassId);
	studentUUId=(studentUUId)?studentUUId:defaultStudentUUId;
	let error=false;
	if(roleConstants.PARENT===UserRole || roleConstants.STUDENT===UserRole){
		if(subjectId && state.subjects.length>0){
			const subject=state.subjects.filter((data:any)=>data.subjectId===subjectId);
			if(subject.length>0){
				values.defaultSubject=subject[0];
			}else{
				error=true;
			}
		}
		if(studentId && state.students.length>0){
			const student=state.students.filter((data:any)=>data.studentId===studentId);
			if(student.length>0){
				values.defaultStudent=student[0];
				values.selectedClassId=student[0].classId;
			}else{
				error=true;
			}
		}
	}else if(roleConstants.TEACHER===UserRole){
		values.selectedSubjectId=subjectId;
		values.selectedClassId=classId;
		if(studentUUId!==''){
			values.defaultStudent=values.defaultStudent?values.defaultStudent:{};
			if(Object.keys(values.defaultStudent).length>0 && values.defaultStudent.studentUUId!==studentUUId){
				values.defaultStudent={};
			}
			if(Object.keys(values.defaultStudent).length===0 && count===0){
				getServiceByUrlAndParam(services.studentByUUID+'/{uuid}', { uuid: studentUUId}).then(studentInfo => {
					axios.get(`${env.userManagementUrl+services.studentProfile+'/'+studentUUId}`, authHeader()).then(studentData => {
						defaultValue.defaultStudent={...studentInfo,...studentData.data.response};
						if(!defaultValue.defaultStudent.studentUUId){
							defaultValue.defaultStudent.studentUUId=studentUUId;
						}
						if(!defaultValue.defaultStudent.classId){
							defaultValue.defaultStudent.classId=classId;
						}
						messageService.sendMessage({ type: msgType.setDefaultValue, data: defaultValue });
					});
				});
			}
		}
	}
	return {...values,error};
}
//function to create default value obj and return
export const returnDefaultValueObj = (props: any) => {
	let defaultValue = { defaultStudent: {}, defaultClass: {}, defaultSubject: {} };
	if (!props) return defaultValue;
	defaultValue.defaultStudent = (props.defaultStudent) ? props.defaultStudent : {};
	defaultValue.defaultClass = (props.defaultClass) ? props.defaultClass : {};
	defaultValue.defaultSubject = (props.defaultSubject) ? props.defaultSubject : {};
	return defaultValue;
}

export const getServiceByUrlParam = (params: any) => {
	let service: any = services, serviceParam: any = serviceParams;
	let serviceURL = '';
	if (params.serviceName) {
		serviceURL = service[params.serviceName];
		if (serviceParam[params.serviceName] && serviceParam[params.serviceName].length > 0) {
			serviceParam[params.serviceName].forEach((param: string) => {
				serviceURL = serviceURL.split('{' + param + '}').join(params[param]);
			})
		}
	}
	return axios.get(`${serviceURL}`, authHeader()).then((response) => {
		return response.data
	}).catch((error) => {
		return Promise.reject(error)
	})
}

export const getServiceByUrlAndParam = (url: string, params: any) => {
	Object.keys(params).forEach((data: any) => {
		url = url.split('{' + data + '}').join(params[data]);
	});
	return axios.get(`${url}`, authHeader()).then((response) => {
		return response.data
	}).catch((error) => {
		return Promise.reject(error)
	})
}

export const putServiceByUrlAndParam = (url: string,body:{}) => {
	return axios.put(`${url}`,body,authHeader()).then((response) => {
		return response.data
	}).catch((error) => {
		return Promise.reject(error)
	})
}

export const postServiceByUrlAndParam = (url: string,body:{}) => {
	return axios.post(`${url}`,body,authHeader()).then((response) => {
		return response.data
	}).catch((error) => {
		return Promise.reject(error)
	})
}
export const deleteServiceByUrlAndParam = (url: string) => {
	return axios.delete(`${url}`,authHeader()).then((response) => {
		return response.data
	}).catch((error) => {
		return Promise.reject(error)
	})
}


export const createRedirectUrlWithParams=(config:{url:string,params:string[],paramValues:string[]},props:any)=>{
	if (config.url && config.url !== '') {
		if (config.params.length > 0) {
			let serviceURL = config.url;
			config.params.forEach((param: string,index:number) => {
				if(config.paramValues.length>0){
					serviceURL = serviceURL.split('{' + param + '}').join(props[config.paramValues[index]]);
				}else{
					serviceURL = serviceURL.split('{' + param + '}').join(props[param]);
				}
			});
			return serviceURL;
		} else {
			return config.url;
		}
	}
	return '';
}

export const getImageFromGCByURL:any=async(url:string)=>{
	if(!url){
		return false;
	}
	const imageParam=url.split('/');
	const bucketName=imageParam[imageParam.length-2];
	const imageName=decodeURI(imageParam[imageParam.length-1]);
	const response=await axios.post(`${env.serverBaseUrl+"api/getImageByName"}`,{bucketName,imageName});
	return response.data;
}

export const getJsonFromGCByURL:any=async(url:string)=>{
	const jsonParam=url.split('/');
	const bucketName=jsonParam[jsonParam.length-2];
	const jsonName=jsonParam[jsonParam.length-1];
	const response=await axios.post(`${env.serverBaseUrl+"api/getJsonByName"}`,{bucketName,jsonName});
	return response.data;
}