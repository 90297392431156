import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { verifyUser } from '_services';
import { SpinnerService } from '_services/spinner.service';
import "./verifyUser.css";
const VerifyUser = (props: any) => {
	const { token } = props.match.params;
	const [message,setMessage]: any = useState({});
	useEffect(() => {
		SpinnerService.sendMessage(true);
		verifyUser(token).then((res:any)=>{
			setMessage(true);
		}).catch(e=>{
			setMessage('Something wrong, please try again!');
			setMessage(false);
		}).finally(()=>{
			SpinnerService.sendMessage(false);
		})
    }, []);
	let content = (<div className="login-body">
		<div className="login-wrap">
			<div className="login-img">
				<img src="/assets/images/login-img.jpg" alt='login' />
			</div>
			<div className="login-form">
				<div className="head_login">
					<img src="/assets/images/login-logo.png" alt="login logo" />
					<h3>Verify Account</h3>
				</div>
				<div className="m-login__container">
					{message===true?<div className="m-login__signin verify-success alert alert-success ">
										<div>Verification successful, Now you can create password from here <Link to={`/create-password/${token}`}>Create Password</Link></div>
									</div>
					:<div className="m-login__signin verify-error  alert alert-error ">
							<div>Verification failed, Please try again after sometime or contact to admin</div>
						</div>}
				</div>:
				<div className="login_footer">
					<Link to='/' onClick={e => e.preventDefault()}>Privacy policy</Link>
				</div>
			</div>
		</div>
	</div>)
	return <Fragment>{content}</Fragment>

};

function mapState(state: any) {
	const { alert } = state;
	return { alert };
}

const connectedLoginPage = withRouter(connect(mapState,{})(VerifyUser));
export { connectedLoginPage as VerifyUser };
