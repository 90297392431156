// LocalStorageService.js
 export const LocalStorageService = (function () {
  function _setToken(tokenObj: any) {
    localStorage.setItem("access_token", tokenObj.access_token);
  }
  function _getAccessToken() {
    let currentUser: any = localStorage.getItem("userData");
    currentUser = (currentUser && currentUser !== '') ? JSON.parse(currentUser) : {};
    return Object.keys(currentUser).length > 0 ? currentUser.access_token : '';
  }
  function _getUserRole() {
    let currentUser: any = localStorage.getItem("userData");
    currentUser = (currentUser && currentUser !== '') ? JSON.parse(currentUser) : {};
    return Object.keys(currentUser).length > 0 ? currentUser.UserRole : '';
  }
  return {
    setToken: _setToken,
    getAccessToken: _getAccessToken,
    getUserRole:_getUserRole
  }
})();