export enum userManagementServices {
    staffProfiles = "staff/profiles",
    addStaff = "staff/profile",
    updateStaff = "staff/profile",
    studentProfiles = "student/profiles",
    updateStudent = "student/profile",
    addStudent = "student/profile",
    classes = "student/classes",
    subjects = "subjects",
    OTPLISTS = "otpList"
}
export const userManagementServiceParams = {
    staffProfiles: ["page", "limit"],
    studentProfiles: ["filterString", "filterProperty"],
}