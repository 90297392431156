export const userConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    USER_DATA_FOUND: 'USER_DATA_FOUND',
    USER_DATA_NOTFOUND: 'USER_DATA_NOTFOUND',
    USER_DEFAULT_SELECTION: 'USER_DEFAULT_SELECTION',
    PERMISSION_SELECTION: 'PERMISSION_SELECTION',
    LOGOUT: 'USERS_LOGOUT',
    GET_INFO: 'GET_INFO'
};

