export enum teacherService {
    teacherInfo = "focus-mvp-1/teachers/{UserUUID}?",
    getSubjectAssignments = "focus-mvp-1/assignments/{classId}?classId={classId}&subjectId={subjectId}&filter={filter}&size={size}&sort={sort}",
    teacherAssignments = "focus-mvp-1/assignments/{classId}?&subjectId={subjectId}&sort={sort}&size={size}",
    getStudentSkills="focus-mvp-1/students/details?studentId={studentId}&subjectId={subjectId}&classId={classId}",
    getTeacherSkills = "focus-mvp-1/subjects/{subjectId}/allskills?",
    getExams = "focus-mvp-1/exams/all?&subjectId={subjectId}&classId={classId}",
    getTeacherStudents = "focus-mvp-1/students/?classId={classId}",
    getTeacherAssignment = "focus-mvp-1/assignments/{classId}?classId={classId}&filter={filter}&size={size}&sort={sort}&subjectId={subjectId}",

}


export const teacherParams = {
    teacherInfo: ["UserUUID"],
    getSubjectAssignments: ["classId", "subjectId", "filter", "size", "sort"],
    teacherAssignments: ["classId", "subjectId", "sort", "size"],
    getStudentSkills:["studentId", "classId", "subjectId"],
    getTeacherSkills: ["subjectId"],
    getExams: ["subjectId", "classId"],
    getTeacherStudents: ["classId"],
    getTeacherAssignment: ["classId", "filter", "size", "sort", "subjectId"],
}