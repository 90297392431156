export const navigation = {
    "sidebarOptions": {
        ROLE_schooladmin: [
            {
                "name": "Dashboard",
                "link": "/",
                "type": "link",
                "key": "dashboard",
                "urlCheckLength": 0
            }, {
                "name": "Principal",
                "link": "/user-management/principal",
                "type": "link",
                "key": "principal",
                "urlCheckLength": 2
            }, {
                "name": "Student",
                "link": "/user-management/student",
                "type": "link",
                "key": "student",
                "urlCheckLength": 2
            }, {
                "name": "Teacher",
                "link": "/user-management/teacher",
                "type": "link",
                "key": "teacher",
                "urlCheckLength": 2
            }, {
                "name": "OTP",
                "link": "/user-management/otp",
                "type": "link",
                "key": "otp",
                "urlCheckLength": 2
            }],
        ROLE_teacher: [
            {
                "name": "Dashboard",
                "link": "/",
                "type": "link",
                "key": "dashboard",
                "urlCheckLength": 0
            }, {
                "name": "Assignment",
                "class": "",
                "link": "/teacher/assignments",
                "type": "link",
                "key": "assignments",
                "permissionKey": "Assignment",
                "urlCheckLength": 2
            }, {
                "name": "Examination",
                "link": "/teacher/examination",
                "type": "link",
                "permissionKey": "Examination",
                "key": "examination",
                "urlCheckLength": 2
            },
        ],
        ROLE_student: [
            {
                "name": "Dashboard",
                "link": "/",
                "type": "link",
                "key": "dashboard",
                "urlCheckLength": 0
            },
            {
                "name": "Subjects",
                "type": "dropdown",
                "link": "/student/subject/overview/{id}",
                "api": false,
                "state": true,
                "key": "subjects",
                "index": ['subjectId', 'subjectName'],
                "keyName": "subjectName",
                "results": [],
                "urlCheckLength": 3,
                "checkLinkUrlArray": ['student/subject/overview', 'student/subject/inter-subject', 'student/subject/intra-subject']
            },
            {
                "name": "Content",
                "type": "dropdown",
                "link": "/student/subject/content/{id}",
                "api": false,
                "state": true,
                "key": "subjects",
                "index": ['subjectId', 'subjectName'],
                "keyName": "subjectName",
                "results": [],
                "permissionKey": "Content",
                "urlCheckLength": 3,
                "checkLinkUrlArray": ['/student/subject/content']
            },
            {
                "name": "Assignment",
                "class": "",
                "type": "dropdown",
                "link": "/student/subject/assignment/{id}",
                "api": false,
                "state": true,
                "key": "subjects",
                "index": ['subjectId', 'subjectName'],
                "keyName": "subjectName",
                "permissionKey": "Assignment",
                "results": [],
                "urlCheckLength": 3,
                "checkLinkUrlArray": ['/student/subject/assignment']
            },
            {
                "name": "Personalised Practice",
                "type": "dropdown",
                "link": "/student/subject/personalised-practice/{id}",
                "api": false,
                "state": true,
                "permissionKey": "PersonalisedPractice",
                "key": "subjects",
                "index": ['subjectId', 'subjectName'],
                "keyName": "subjectName",
                "results": [],
                "urlCheckLength": 3,
                "checkLinkUrlArray": ['/student/subject/personalised-practice']
            }
        ],
        ROLE_guardian: [
            {
                "name": "Dashboard",
                "link": "/",
                "type": "link",
                "key": "dashboard",
                "urlCheckLength": 0
            },
            {
                "name": "Subject",
                "type": "dropdown",
                "link": "/parent/subject/overview/{id}",
                "api": false,
                "state": true,
                "key": "subjects",
                "index": ['subjectId', 'subjectName'],
                "results": [],
                "urlCheckLength": 2
            },
            {
                "name": "Personalised Practice",
                "type": "dropdown",
                "link": "/parent/subject/personalised-practice/{id}",
                "api": false,
                "state": true,
                "key": "subjects",
                "index": ['subjectId', 'subjectName'],
                "keyName": "subjectName",
                "results": [],
                "urlCheckLength": 3,
                "checkLinkUrlArray": ['/parent/subject/personalised-practice']
            }
        ],
        ROLE_principal: [
            {
                "name": "Dashboard",
                "link": "/",
                "type": "link",
                "key": "dashboard",
                "urlCheckLength": 0
            },
            {
                "name": "Classes",
                "type": "dropdown",
                "link": "/principal/class/{id}",
                "api": false,
                "state": true,
                "key": "classes",
                "index": ['classId', 'classCode'],
                "results": [],
                "urlCheckLength": 2,
                "checkLinkUrlArray": ['principal/class', 'principal/subject']
            }
        ],
        ROLE_superadmin: [
            {
                "name": "Dashboard",
                "link": "/",
                "type": "link",
                "key": "dashboard",
                "urlCheckLength": 0
            },
            {
                "name": "personalised-practice",
                "link": "/personalised-practice",
                "type": "link",
                "key": "personalised-practice",
                "urlCheckLength": 1,
                "checkLinkUrlArray": ['personalised-practice']
            },
        ]
    },
    "headerOptions": {
        ROLE_schooladmin: [],
        ROLE_superadmin: [
            {
                "name": "classes",
                "type": "dropdown",
                "link": "",
                "api": false,
                "state": true,
                "key": "classes",
                "index": ['standardId', 'standardCode'],
                "depended": true,
                "dependedOption": {
                    "name": "Subject",
                    "type": "dropdown",
                    "link": "",
                    "api": false,
                    "state": true,
                    "key": "subjects",
                    "index": ['subjectId', 'subjectName'],
                    "results": [],
                },
                "results": [],
            }
        ],
        ROLE_teacher: [
            {
                "name": "classes",
                "type": "dropdown",
                "link": "",
                "api": false,
                "state": true,
                "key": "classes",
                "index": ['classId', 'classCode'],
                "depended": true,
                "dependedOption": {
                    "name": "Subject",
                    "type": "dropdown",
                    "link": "",
                    "api": false,
                    "state": true,
                    "key": "subjects",
                    "index": ['subjectId', 'subjectName'],
                    "results": [],
                },
                "results": [],
            }
        ],
        ROLE_guardian: [
            {
                "name": "Children",
                "type": "dropdown",
                "link": "",
                "api": false,
                "state": true,
                "key": "children",
                "index": ['studentId', 'fullName'],
                "results": [],
            }
        ],
        ROLE_student: [
            {
                "name": "Student",
                "type": "dropdown",
                "link": "",
                "api": false,
                "state": true,
                "key": "subjects",
                "index": [],
                "results": [],
            }
        ]
    }
}