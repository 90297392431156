import React, { Fragment, useState } from 'react';
import "./createPassword.css";
import { useForm } from "react-hook-form";
import { connect } from 'react-redux';
import { Link, useHistory, withRouter } from 'react-router-dom';
import { authCreatePassword } from '_services';
import { SpinnerService } from '_services/spinner.service';
const CreatePassword = (props: any) => {
	const { register, handleSubmit, errors,watch } = useForm();
	const { otp } = props.match.params;
	const history = useHistory();
	const onSubmit = (data: any) => {
		SpinnerService.sendMessage(true);
		authCreatePassword(otp?otp:data.otp,{
			"password" : data.password,
			"reTypePassword" : data.confirmpassword
		}).then(data=>{
			SpinnerService.sendMessage(false);
			window.alert('Password successfully created');
			history.push("/login");
		}).catch((errors) => {
			window.alert('Something went wrong, please try again after sometime.');
			SpinnerService.sendMessage(false);
			history.push("/login");
		});
	}
	const { alert }: any = props;
	let content = (<div className="login-body">
		<div className="login-wrap">
			<div className="login-img">
				<img src="/assets/images/login-img.jpg" alt='login' />
			</div>
			<div className="login-form">
				<div className="head_login">
					<img src="/assets/images/login-logo.png" alt="login logo" />
					<h4>Create new password.</h4>
				</div>
				<div className="m-login__container">
					<div className="m-login__signin">
						{alert.message && <div className={`alert ${alert.type}`}>{alert.message}</div>}
						<form className="form" onSubmit={handleSubmit(onSubmit)}>
							<div className="row">
								<div className="col-md-12">
									<div className="form-body">
										{!props.match.params.otp?<div className="form-group">
											<input
												id="otp"
												type="text"
												className="form-control"
												autoComplete="false"
												name="otp"
												value={otp}
												placeholder=" otp"
												ref={register({ required: true })}
											/>
											{errors.otp && <span>This field is required</span>}

										</div>:''}
										<div className="form-group">
											<input
												id="password"
												type="password"
												className="form-control"
												autoComplete="false"
												name="password"
												placeholder=" Password"
												ref={register({ required: true })}
											/>
											{errors.password && <span>This field is required</span>}

										</div>
										<div className="form-group">
											<input
												id="confirmpassword"
												type="password"
												autoComplete="false"
												className="form-control"
												name="confirmpassword"
												placeholder="Confirm Password"
												ref={register({required: true,
													validate: (value) => value === watch('password') || "Passwords don't match."
												  })}
											/>
											{errors.confirmpassword && <span>Passwords don't match.</span>}

										</div>
										<div className="form-group">
											<div className="remember_check" style={{display:"flow-root"}}>												
												<Link to='/login'>Back To Login</Link>
											</div>
										</div>
										<div className="form-group pt-10">
											<button
												type="submit"
												className="btn btn-primary login_btn"
												name="create-password"
											>
												Create Password
										</button>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
				<div className="login_footer">
					<Link to='/' onClick={e => e.preventDefault()}>Privacy policy</Link>
				</div>
			</div>
		</div>
	</div>)
	return <Fragment>{content}</Fragment>

};

function mapState(state: any) {
	const { alert } = state;
	return { alert };
}

const connectedLoginPage = withRouter(connect(mapState,{})(CreatePassword));
export { connectedLoginPage as CreatePassword };
