export enum principalService {
    principalInfo = "focus-mvp-1/principal/{UserUUID}?",
    getPrincipalClassDetails = "focus-mvp-1/principal/details?&classId={classId}",
    topStudentUrl = "focus-mvp-1/student/order?&classId={classId}&subjectId={subjectId}&size={size}",
    topClassStudentUrl = "focus-mvp-1/student/order?&classId={classId}&size={size}",
    topSkillUrl = "focus-mvp-1/skills/order?&classId={classId}&subjectId={subjectId}&size={size}&filter=Top",
    bottomSkillUrl = "focus-mvp-1/skills/order?&classId={classId}&subjectId={subjectId}&size={size}&filter=Bottom",
}

export const principalParams = {
    principalInfo: ["UserUUID"],
    getPrincipalClassDetails: ["classId"],
    topStudentUrl: ["classId","subjectId","size"],
    topClassStudentUrl: ["classId","subjectId","size"]
}