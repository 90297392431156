export enum parentService {
    parentInfo = "focus-mvp-1/parents/{UserUUID}?studentUUID={UserUUID}",
    getParentSubjectSkill = "focus-mvp-1/skills/{subjectId}?studentId={studentId}",
    getParentSubjectAssignments = "focus-mvp-1/assignments/{classId}?classId={classId}&filter={filter}&size={size}&sort={sort}&subjectId={subjectId}",
    getParentClassDetails = "focus-mvp-1/class/details?subjectId={subjectId}&classId={classId}",
    getParentStudentDetails = "focus-mvp-1/students/details?studentId={studentId}&classId={classId}&subjectId={subjectId}",
}

export const parentParams = {
    parentInfo: ["UserUUID"],
    getParentSubjectSkill: ["subjectId", "studentId"],
    getParentSubjectAssignments: ["classId", "filter", "size", "sort", "subjectId"],
    getParentClassDetails: ["subjectId", "classId"],
    getParentStudentDetails: ["studentId", "classId", "subjectId"],
}

