import { roleConstants, userConstants } from '_constants';
export function user(state: any = { defaultStudent: {}, defaultClass: {}, defaultSubject: {}, subjects: [], students: [], classes: [] }, action: any) {
  switch (action.type) {
    case userConstants.GET_INFO:
      if (action.data.studentId) {
        state.students = [action.data];
        state.subjects = action.data.subjects;
        state.permissionData = action.data.permission;
        state.defaultSubject = (state.subjects.length > 0) ? state.subjects[0] : {};
        state.defaultStudent = action.data;
      } else if (action.data.teacherId) {
        let subjects: any = [];
        action.data.classes.forEach((data: any) => {
          subjects = [...subjects, ...data.subjects];
        });
        state.classes = action.data.classes;
        state.defaultClass = (state.classes.length > 0) ? state.classes[0] : {};
        state.subjects = subjects;
        state.permissionData = action.data.permission;
        state.defaultSubject = (state.subjects.length > 0) ? state.subjects[0] : {};
      } else if (action.data.parentId) {
        let subjects: any = [];
        action.data.children.forEach((data: any) => {
          subjects = [...subjects, ...data.subjects];
        });
        state.students = action.data.children;
        state.defaultStudent = (state.students.length > 0) ? state.students[0] : {};
        state.subjects = subjects;
        state.permissionData = action.data.permission;
        state.defaultSubject = (state.subjects.length > 0) ? state.subjects[0] : {};
      } else if (action.data.user && action.data.user.UserRole == roleConstants.SUPERADMIN) {
        state.classes = action.data.classes;
        state.permissionData = action.data.permission;
        state.defaultClass = (state.classes.length > 0) ? state.classes[0] : {};
        if (state.defaultClass && state.classes[0].subjects.length > 0) { state.defaultSubject = state.classes[0].subjects[0] }
      }
      return { ...state, ...{ info: action.data } };
    case userConstants.USER_DEFAULT_SELECTION:
      return { ...state, ...action.defaultValue };
    case userConstants.PERMISSION_SELECTION:
        return { ...state, ...action.permission };
    case userConstants.LOGOUT:
      return { state: {} };
    default:
      return state
  }
}