import { userConstants, errorConstants } from "_constants";
import { authLogin, authLogout, getUserInfo } from "_services";
import { alertActions } from ".";
import { history } from "_helpers";

export const userActions = {
  login,
  logout,
  getUserData,
  setDefaultValues,
  setPermission
};
const loginsuccess = (user: any) => {
  user.UserRole =user.role;
  localStorage.setItem('userData', JSON.stringify(user));
  return { type: userConstants.LOGIN_SUCCESS, user };
};
function login(username: string, password: string) {
  const request = (user: any) => {
    return { type: userConstants.LOGIN_REQUEST, user };
  };
  const failure = (error: any) => {
    return { type: userConstants.LOGIN_FAILURE, error };
  };
  return (dispatch: any) => {
    dispatch(request({ username }));
    authLogin(username, password).then(
      (user) => {
        dispatch(loginsuccess(user));
        history.push("/");
      },
      (error) => {
        dispatch(failure(errorConstants.loginError));
        dispatch(alertActions.error(errorConstants.loginError));
      }
    );
  };
}

//function to get user information according role
function getUserData(user: any) {
  const success = (data: any) => {
    return { type: userConstants.GET_INFO, data }
  }
  return (dispatch: any) => {
    getUserInfo(user).then(
      (data: any) => {
        dispatch(loginsuccess({...user,...data.loginData}));
        data.data.emailAddress=data.userInfo.emailaddress;
        data.data.firstName=data.userInfo.firstname;
        data.data.name=data.userInfo.firstname;
        data.data.gender=data.userInfo.gender;
        data.data.image=data.userInfo.image;
        data.data.lastName='';
        data.data.middleName='';
        data.data.mobileNumber='';
        data.data.salutation="Mr";
        data.data.studentUUId=data.userInfo.uuid;
        data.data.permission=data.loginData.permission;
        dispatch(success(data.data));
      },
      (error: any) => {
        authLogout();
        dispatch({ type: userConstants.LOGOUT });
      }
    )
  }
}

function setDefaultValues(defaultValue: any) {
  const request = () => {
    return { type: userConstants.USER_DEFAULT_SELECTION, defaultValue };
  };
  return (dispatch: any) => { dispatch(request()) };
}

function setPermission(permission: any) {
  const request = () => {
    return { type: userConstants.PERMISSION_SELECTION, permission };
  };
  return (dispatch: any) => { dispatch(request()) };
}

function logout() {
  authLogout();
  return { type: userConstants.LOGOUT };
}
