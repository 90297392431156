import React, { Suspense } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { Spinner } from '_common';
import { roleConstants } from '_constants';

const Overview = React.lazy(() => import('_components/_overview/index'));
const TeacherOverview = React.lazy(() => import('_components/_overview/teacher'));
const InterSubject = React.lazy(() => import('_components/_skillImpact/interSubject/index'));
const IntraSubject = React.lazy(() => import('_components/_skillImpact/intraSubject/index'));
const Content = React.lazy(() => import('_components/_content/index'));
const ContentDetail = React.lazy(() => import('_components/_content/detail'));
const PersonalisedPractice = React.lazy(() => import('_components/_personalisedPractice/index'));
const PersonalisedPracticeDetail = React.lazy(() => import('_components/_personalisedPractice/detail'));
const PersonalisedPracticeDetailQuestion = React.lazy(() => import('_components/_personalisedPractice/detailQuestion'));
const PersonalisedPracticeAttemptQuestion = React.lazy(() => import('_components/_personalisedPractice/attemptQuestion'));
const Assignment = React.lazy(() => import('_components/_assignment/index'));
const AssignmentDetail = React.lazy(() => import('_components/_assignment/detail'));
const AssignmentDetailQuestion = React.lazy(() => import('_components/_assignment/detailQuestion'));
const AssignmentAttemptQuestion = React.lazy(() => import('_components/_assignment/attemptQuestion'));
const StudentDashboard = React.lazy(() => import('_components/_student/dashboard/index'));
const TeacherDashboard = React.lazy(() => import('_components/_teacher/dashboard/index'));
const StudentProfiles = React.lazy(() => import('_components/_teacher/studentProfile/index'));
const Assignments = React.lazy(() => import('_components/_teacher/assignments/index'));
const Marks = React.lazy(() => import('_components/_teacher/marks/index'));
const Examination = React.lazy(() => import('_components/_teacher/examination/index'));
const ExamDatasheet = React.lazy(() => import('_components/_teacher/exam-datasheet/index'));
const ExamGrade = React.lazy(() => import('_components/_teacher/exam-grade/index'));
const ParentDashboard = React.lazy(() => import('_components/_parent/dashboard/index'));
const ClassProfile = React.lazy(() => import('_components/_parent/classProfile/index'));
const SchoolDashboard = React.lazy(() => import('_components/_school/dashboard/index'));
const Class = React.lazy(() => import('_components/_school/class/index'));
const Subject = React.lazy(() => import('_components/_school/subject/index'));
const AdminDashboard = React.lazy(() => import('_components/_userManagement/dashboard/index'));
const AdminPrincipal = React.lazy(() => import('_components/_userManagement/principal/index'));
const AdminStudent = React.lazy(() => import('_components/_userManagement/student/index'));
const AdminTeacher = React.lazy(() => import('_components/_userManagement/teacher/index'));
const AdminOTPlist = React.lazy(() => import('_components/_userManagement/otplist/index'));
const Planner = React.lazy(() => import('_components/_teacher/planner/index'));
const SuperAdminDashboard = React.lazy(() => import('_components/_superAdmin/dashboard/index'));
const SuperAdminPersonalisePractice = React.lazy(() => import('_components/_superAdmin/personalise/index'));

export const loadRouteComponent = (user) => {
    const { UserRole } = user;
    let route = false;
    switch (UserRole) {
        case roleConstants.TEACHER:
            route = (<Suspense fallback={<div style={{ position: 'absolute', left: "45%", top: "50%", fontSize: "200%" }}>
                <Spinner />
            </div>}>
                <Switch>
                    <Route path='/teacher/students'><StudentProfiles /></Route>
                    <Route path='/teacher/assignments/mark/:assignmentId/:completed'><Marks /></Route>
                    <Route path='/teacher/assignments'><Assignments /></Route>
                    <Route exact path='/teacher/examination/datasheet'><ExamDatasheet /></Route>
                    <Route exact path='/teacher/examination/grade'><ExamGrade /></Route>
                    <Route exact path='/teacher/examination'><Examination /></Route>
                    <Route exact path='/teacher/planner'><Planner /></Route>
                    <Route exact path='/teacher/class/intra-subject'><IntraSubject config="teacherClass" route="class" /></Route>
                    <Route exact path='/teacher/class/inter-subject'><InterSubject config="teacherClass" route="class" /></Route>
                    <Route exact path='/teacher/class'><TeacherOverview config="teacherClass" isClass="true" /></Route>
                    <Route exact path='/teacher/class/personalised-practice/:subjectId'><PersonalisedPractice config="teacherClass" /></Route>
                    <Route exact path='/teacher/class/personalised-practice/:subjectId/:subSkillId'><PersonalisedPracticeDetail configTwo="teacherClassPersonalisedPracticeDetail" /></Route>
                    <Route exact path='/teacher/class/personalised-practice/:teacherId/:subjectId/:subSkillId/:questionId'><PersonalisedPracticeDetailQuestion configTwo="teacherClassPersonalisedPracticeQuestionDetail" /></Route>
                    <Route exact path='/teacher/class/assignment/:subjectId'><Assignment config="teacherClass" /></Route>
                    <Route exact path='/teacher/class/assignment/:subjectId/:subSkillId'><AssignmentDetail configTwo="teacherClassAssignmentDetail" /></Route>
                    <Route exact path='/teacher/class/assignment/:teacherId/:subjectId/:subSkillId/:questionId'><AssignmentDetailQuestion configTwo="teacherClassAssignmentQuestionDetail" /></Route>
                    <Route exact path='/teacher/class/content/:subjectId'><Content config="teacherClass" /></Route>
                    <Route exact path='/teacher/class/content/:skillId/:subSkillId'><ContentDetail config="teacherClassContentDetail" /></Route>
                    <Route exact path='/'><TeacherDashboard /></Route>
                    <Route exact path='/teacher/student/:studentUUId/:subjectId'><TeacherOverview config="teacherStudent" isClass="false" /></Route>
                    <Route exact path='/teacher/student/intra-subject/:studentUUId/:subjectId/:studentId'><IntraSubject config="teacherStudent" route="student" /></Route>
                    <Route exact path='/teacher/student/inter-subject/:studentUUId/:subjectId/:studentId'><InterSubject config="teacherStudent" route="student" /></Route>
                    <Route exact path="/teacher/student/subject/content/:studentUUId/:subjectId"><Content isClass="false" config="teacherStudent" /></Route>
                    <Route exact path='/teacher/student/personalised-practice/:studentUUId/:subjectId'><PersonalisedPractice config="teacherStudent" /></Route>
                    <Route exact path='/teacher/student/personalised-practice/:studentUUId/:subjectId/:subSkillId'><PersonalisedPracticeDetail configTwo="teacherPersonalisedPracticeDetail" /></Route>
                    <Route exact path='/teacher/student/personalised-practice/:studentUUId/:subjectId/:subSkillId/:questionId'><PersonalisedPracticeDetailQuestion configTwo="teacherPersonalisedPracticeQuestionDetail" /></Route>
                    <Route exact path='/teacher/student/assignment/:studentUUId/:subjectId'><Assignment config="teacherStudent" /></Route>
                    <Route exact path='/teacher/student/assignment/:studentUUId/:subjectId/:subSkillId'><AssignmentDetail configTwo="teacherAssignmentDetail" /></Route>
                    <Route exact path='/teacher/student/assignment/:studentUUId/:subjectId/:subSkillId/:questionId'><AssignmentDetailQuestion configTwo="teacherAssignmentQuestionDetail" /></Route>
                    <Redirect to='/' />
                </Switch>
            </Suspense>);
            break;
        case roleConstants.PARENT:
            route = (<Suspense fallback={<div style={{ position: 'absolute', left: "45%", top: "50%", fontSize: "200%" }}>
                <Spinner />
            </div>}>
                <Switch>
                    <Route exact path='/parent/subject/overview/:subjectId'><Overview isClass="false" config="parent" /></Route>
                    <Route exact path='/parent/subject/inter-subject/:subjectId'><InterSubject config="parent" route="student" /></Route>
                    <Route exact path='/parent/subject/intra-subject/:subjectId'><IntraSubject config="parent" route="student" /></Route>
                    <Route exact path='/subject/class-profile'><ClassProfile config="parent" /></Route>
                    <Route exact path='/parent/subject/personalised-practice/:subjectId'><PersonalisedPractice config="parent" /></Route>
                    <Route exact path='/parent/subject/personalised-practice/:subjectId/:subSkillId'><PersonalisedPracticeDetail configTwo="parentPersonalisedPracticeDetail" /></Route>
                    <Route exact path='/parent/personalised-practice/:subjectId/:subSkillId/:questionId'><PersonalisedPracticeDetailQuestion configTwo="parentPersonalisedPracticeQuestionDetail" /></Route>
                    <Route exact path='/'><ParentDashboard /></Route>
                    <Redirect to='/' />
                </Switch>
            </Suspense>);
            break;
        case roleConstants.STUDENT:
            route = (<Suspense fallback={<div style={{ position: 'absolute', left: "45%", top: "50%", fontSize: "200%" }}>
                <Spinner />
            </div>}>
                <Switch>
                    <Route exact path="/student/subject/overview/:subjectId"><Overview isClass="false" config="student" /></Route>
                    <Route exact path='/student/subject/inter-subject/:subjectId'><InterSubject config="student" route="student" /></Route>
                    <Route exact path='/student/subject/intra-subject/:subjectId'><IntraSubject config="student" route="student" /></Route>
                    <Route exact path="/student/subject/content/:subjectId"><Content isClass="false" config="student" /></Route>
                    <Route exact path='/student/subject/content/:skillId/:subSkillId'><ContentDetail config="studentContentDetail" /></Route>
                    <Route exact path='/student/subject/assignment/:subjectId'><Assignment config="student" /></Route>
                    <Route exact path='/student/subject/assignment/:subjectId/:subSkillId'><AssignmentDetail configTwo="studentAssignmentDetail" /></Route>
                    <Route exact path='/student/assignment/attempt/:subjectId/:skillId/:subSkillId'><AssignmentAttemptQuestion configTwo="studentAttemptQuestion" /></Route>
                    <Route exact path='/student/assignment/:subjectId/:subSkillId/:questionId'><AssignmentDetailQuestion configTwo="studentAssignmentQuestionDetail" /></Route>
                    <Route exact path='/student/subject/personalised-practice/:subjectId'><PersonalisedPractice config="student" /></Route>
                    <Route exact path='/student/subject/personalised-practice/:subjectId/:subSkillId'><PersonalisedPracticeDetail configTwo="studentPersonalisedPracticeDetail" /></Route>
                    <Route exact path='/student/personalised-practice/:subjectId/:subSkillId/:questionId'><PersonalisedPracticeDetailQuestion configTwo="studentPersonalisedPracticeQuestionDetail" /></Route>
                    <Route exact path='/student/personalised-practice/attempt/:subjectId/:skillId/:subSkillId'><PersonalisedPracticeAttemptQuestion configTwo="studentAttemptQuestion" /></Route>
                    <Route exact path='/'><StudentDashboard /></Route>
                    <Redirect to='/' />
                </Switch>
            </Suspense>)
            break;
        case roleConstants.PRINCIPAL:
            route = (<Suspense fallback={<div style={{ position: 'absolute', left: "45%", top: "50%", fontSize: "200%" }}>
                <Spinner />
            </div>}>
                <Switch>
                    <Route exact path='/principal/class/:classId'><Class /></Route>
                    <Route exact path='/principal/subject/:classId/:subjectId'><Subject /></Route>
                    <Route exact path='/'><SchoolDashboard /></Route>
                    <Redirect to='/' />
                </Switch>
            </Suspense>);
            break;
        case roleConstants.SCHOOLADMIN:
            route = (<Suspense fallback={<div style={{ position: 'absolute', left: "45%", top: "50%", fontSize: "200%" }}>
                <Spinner />
            </div>}>
                <Switch>
                    <Route exact path='/principal/class/:classId'><Class /></Route>
                    <Route exact path='/user-management/principal'><AdminPrincipal /></Route>
                    <Route exact path='/user-management/student'><AdminStudent /></Route>
                    <Route exact path='/user-management/teacher'><AdminTeacher /></Route>
                    <Route exact path='/user-management/otp'><AdminOTPlist /></Route>
                    <Route exact path='/'><AdminDashboard /></Route>
                    <Redirect to='/' />
                </Switch>
            </Suspense>);
            break;
        case roleConstants.SUPERADMIN:
            route = (<Suspense fallback={<div style={{ position: 'absolute', left: "45%", top: "50%", fontSize: "200%" }}>
                <Spinner />
            </div>}>
                <Switch>
                    <Route exact path='/'><SuperAdminDashboard /></Route>
                    <Route exact path='/personalised-practice'><SuperAdminPersonalisePractice /></Route>
                    <Redirect to='/' />
                </Switch>
            </Suspense>);
            break;
        default:
            route = (<Redirect to='/Login' />);
            break;
    }
    return route;
}