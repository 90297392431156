import { services } from "_enums";
import { userManagementServices } from "_enums";
import { authHeader } from "_helpers";
import axios from "axios";
import { createFormData, LocalStorageService } from "_services";
import { env } from "environment";
import { SpinnerService } from "./spinner.service";


export const checkAuthService = (userData: string) => {
  const users: any = localStorage.getItem("users");
  const userStorage = JSON.parse(users);
  return userStorage[userData];
};

export const authLogin = (username: string, password: string) => {
  SpinnerService.sendMessage(true);
  return axios
    .post(`${env.authUrl+services.login}`, createFormData([{ "grant_type": "password" }, { "username": username }, { "password": password }]), authHeader())
    .then((response) => {
      SpinnerService.sendMessage(false);
      const user = response.data
      return user
    })
    .catch((errors) => {
      SpinnerService.sendMessage(false);
      if (errors.response.status === 401) {
        // auto logout if 401 response returned from api
        authLogout();
      }
      const error = errors.response.data.error
      return Promise.reject(error);
    })
};
export const authForgotPassword = (username: string) => {
  return axios
    .post(`${env.authUrl+services.forgotPassword+'/'+username}`,{},authHeader());
};
export const authCreatePassword = (otp:string,data: {}) => {
  return axios
    .post(`${env.authUrl+services.createPassword+'/'+otp}`,data,authHeader());
};
export const verifyUser = (token:any) => {
  return axios
    .get(`${env.authUrl+services.verifyUser+'/'+token}`,authHeader());
};
export const getOtplists=()=>{
  return axios.get(`${env.authUrl+userManagementServices.OTPLISTS}`,{ headers: { 'Authorization': `bearer ${LocalStorageService.getAccessToken()}`  } }).then((response:any) => {
      return response.data.response
  }).catch((error) => {
      return Promise.reject(error)
  })
}
export const authLogout = () => {
  // remove user from local storage to log user out
  localStorage.removeItem("userData");
};
