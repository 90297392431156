import React, { useEffect, useState } from 'react';
import { getServiceByUrlAndParam } from '_services';
import { Link } from 'react-router-dom';
import { Spinner } from '_common';

export const AllSubjectAssignment = (props: any) => {
    const [assignment, setAssignment]: any = useState({});
    const assignmentParams = [{ filter: "upcoming" }, { filter: "pending" }, { filter: "submitted" }];
    const { subjectId,classId } = props.serviceParams;
    const getData = () => {
        let batchAction: any = [];
        assignmentParams.forEach((obj: any) => {
            batchAction.push(getServiceByUrlAndParam(props.url, { ...props.serviceParams, ...obj }));
        });
        Promise.all(batchAction).then((data: any) => {
            let allAssignmentRes: any = {};
            assignmentParams.forEach((obj: any, index: number) => {
                allAssignmentRes[obj.filter] = data[index];
            });
            setAssignment(allAssignmentRes);
        });
    }
    useEffect(() => {
        getData();
    }, [subjectId,classId]);
    let allTabAssignment: any = [];
    if (Object.keys(assignment).length > 0) {
        assignmentParams.forEach((obj: any, j: number) => {
            let assignmentList: any = [];
            if (assignment[obj.filter]) {
                assignment[obj.filter].forEach((assign: any, index: number) => {
                    let classname = 'Percentages_bg';
                    if (index % 2) {
                        classname = "linear_bg";
                    }
                    assignmentList.push(<div className={classname} key={`child` + assign.assignmentId}>
                        <h4 key={`h4` + assign.assignmentId}>{assign.skillName}</h4>
                        <p key={`p` + assign.assignmentId}>{assign.description}</p>
                    </div>);
                });
                allTabAssignment.push(
                    <div id={obj.filter} key={obj.filter + j} className={`tab-pane fade in  shadow bg-white ` + (j === 0 ? "active" : '')} style={{ height: "100%" }}>
                        {assignmentList}
                    </div>);
            }
        });
    }
    let spinner = true;
    if (allTabAssignment.length > 0) {
        spinner = false;
    }
    return <div className="col-md-5">
        <div className="tab_section">
            <h5>Assigment</h5>
            <ul className="nav nav-tabs">
                <li className="active"><Link data-toggle="tab" to="#upcoming">Upcoming</Link></li>
                <li ><Link data-toggle="tab" to="#pending">Pending</Link></li>
                <li><Link data-toggle="tab" to="#submitted">Submitted</Link></li>
            </ul>
            <div className="tab-content" style={{ overflowY: "auto", height: "450px" }}>
                {spinner ? <div style={{ position: 'absolute', left: "35%", top: "45%", fontSize: "200%" }}>
                    <Spinner />
                </div> : allTabAssignment}
            </div>
        </div>
    </div>;
}