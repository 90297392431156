import { alertConstants } from '../_constants';
import {alertTypeEnum} from '_enums/alertType.enum';

export function alert(state = {}, action:any) {
  switch (action.type) {
    case alertConstants.SUCCESS:
      return {
        type: alertTypeEnum.success,
        message: action.message
      };
    case alertConstants.ERROR:
      return {
        type: alertTypeEnum.error,
        message: action.message
      };
    case alertConstants.CLEAR:
      return {};
    default:
      return state
  }
}
