import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { Header, Sidebar } from '_common';
import { roleConstants } from '_constants';
import { userActions } from "_actions";
import { loadRouteComponent } from '_routes/index';
import {mapDefaultValueInRedux} from "_services";
var isInitiate=false;
const PrivateRoute = (props) => {
	const { user } = props.authentication;
	let { info} = props.user;
	if (roleConstants.PARENT === user.UserRole) {
		let subjects = { subjects: (props.user && props.user.defaultStudent) ? props.user.defaultStudent.subjects : [] };
		info = { ...info, ...subjects };
	}
	const { getUserInfo } = props;
	useEffect(() => {
		if(!isInitiate){
			isInitiate=true;
			getUserInfo(user);
		}
		mapDefaultValueInRedux({setDefaultValues:props.setDefaultValues,setPermission:props.setPermission}); 
	}, [getUserInfo, user,props.setDefaultValues]);
	const children = loadRouteComponent(user, info);
	const logout=()=>{
		isInitiate=false;
		props.logout();
	}
	return (<Fragment >
		{info && Object.keys(info).length>0?<div id="wrapper">
			<Sidebar UserRole={user.UserRole} info={info} userInfo={props.user} authentication={props.authentication}  />
			<div id="page-content-wrapper">
				<Header user={user} info={info} userInfo={props.user} logout={logout} />
				{children}
			</div>
		</div>:''}
	</Fragment>
	);
};

function mapState(state) {
	const { alert, authentication, user } = state;
	return { alert, authentication, user };
}
const actionCreators = {
	getUserInfo: userActions.getUserData,
	setDefaultValues: userActions.setDefaultValues,
	setPermission:userActions.setPermission,
	logout: userActions.logout,
};
const connectedApp = connect(mapState, actionCreators)(PrivateRoute);
export { connectedApp as PrivateRoute };
