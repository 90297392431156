export const overviewHeaderConfig: any = {
    student: {
        addImageSectionInHeader: false,
        isClass: false,
        icon: {
            show: true,
            redirectUrl: '/student/subject/overview/{subjectId}',
            redirectParams: ['subjectId']
        },
        overview: {
            show: true,
            check: true,
            label: "Overview",
            checkByName: "currentView",
            checkValue: "overview",
            addClassAfterCheck: "active",
            redirectUrl: '/student/subject/overview/{subjectId}',
            redirectParams: ['subjectId']
        },
        skillImpact: {
            show: true,
            check: true,
            checkByName: "currentView",
            checkValue: ["intra-subject", "inter-subject"],
            addClassAfterCheck: "open"
        },
        intraSubject: {
            show: true,
            check: true,
            checkByName: "currentView",
            checkValue: "intra-subject",
            label: "Intra-Subject Impact Analysis",
            addClassAfterCheck: "active",
            redirectUrl: '/student/subject/intra-subject/{subjectId}',
            redirectParams: ['subjectId']
        },
        interSubject: {
            show: true,
            check: true,
            checkByName: "currentView",
            checkValue: "inter-subject",
            label: "Inter-Subject Impact Analysis",
            addClassAfterCheck: "active",
            redirectUrl: '/student/subject/inter-subject/{subjectId}',
            redirectParams: ['subjectId']
        },
        classProfile: {
            show: false
        },
        content: {
            show: true,
            check: true,
            checkByName: "currentView",
            checkValue: "content",
            permissionKey: "Content",
            label: "Content",
            addClassAfterCheck: "active",
            redirectUrl: '/student/subject/content/{subjectId}',
            redirectParams: ['subjectId']
        },
        personalisedPractice: {
            show: true,
            check: true,
            checkByName: "currentView",
            permissionKey: "PersonalisedPractice",
            checkValue: "personalisedPractice",
            label: "Personalised Practice",
            addClassAfterCheck: "active",
            redirectUrl: '/student/subject/personalised-practice/{subjectId}',
            redirectParams: ['subjectId']
        },
        assignment: {
            show: true,
            check: true,
            defaultClass: '',
            checkByName: "currentView",
            checkValue: "assignment",
            permissionKey: "Assignment",
            label: "Assignment",
            addClassAfterCheck: "active",
            redirectUrl: '/student/subject/assignment/{subjectId}',
            redirectParams: ['subjectId']
        }
    },
    parent: {
        addImageSectionInHeader: false,
        isClass: false,
        icon: {
            show: true,
            redirectUrl: '/parent/subject/overview/{subjectId}',
            redirectParams: ['subjectId']
        },
        overview: {
            show: true,
            check: true,
            checkByName: "currentView",
            checkValue: "overview",
            label: "Overview",
            addClassAfterCheck: "active",
            redirectUrl: '/parent/subject/overview/{subjectId}',
            redirectParams: ['subjectId']
        },
        skillImpact: {
            show: true,
            check: true,
            checkByName: "currentView",
            checkValue: ["intra-subject", "inter-subject"],
            addClassAfterCheck: "open"
        },
        intraSubject: {
            show: true,
            check: true,
            checkByName: "currentView",
            checkValue: "intra-subject",
            label: "Intra-Subject Impact Analysis",
            addClassAfterCheck: "active",
            redirectUrl: '/parent/subject/intra-subject/{subjectId}',
            redirectParams: ['subjectId']
        },
        interSubject: {
            show: true,
            check: true,
            checkByName: "currentView",
            checkValue: "inter-subject",
            label: "Inter-Subject Impact Analysis",
            addClassAfterCheck: "active",
            redirectUrl: '/parent/subject/inter-subject/{subjectId}',
            redirectParams: ['subjectId']
        },
        personalisedPractice: {
            show: true,
            check: true,
            checkByName: "currentView",
            checkValue: "personalisedPractice",
            label: "Personalised Practice",
            addClassAfterCheck: "active",
            redirectUrl: '/parent/subject/personalised-practice/{subjectId}',
            redirectParams: ['subjectId']
        },
        classProfile: {
            show: true,
            check: true,
            checkByName: "currentView",
            checkValue: "Class-Profile",
            addClassAfterCheck: "active",
            redirectUrl: '/subject/class-profile',
            redirectParams: []
        }
    },
    teacherStudent: {
        addImageSectionInHeader: true,
        isClass: false,
        icon: {
            show: true,
            redirectUrl: '/teacher/student/{studentUUId}/{subjectId}',
            redirectParams: ['studentUUId', 'subjectId']
        },
        overview: {
            show: true,
            check: true,
            checkByName: "currentView",
            checkValue: "overview",
            label: "Overview",
            addClassAfterCheck: "active",
            redirectUrl: '/teacher/student/{studentUUId}/{subjectId}',
            redirectParams: ['studentUUId', 'subjectId']
        },
        skillImpact: {
            show: true,
            check: true,
            checkByName: "currentView",
            checkValue: ["intra-subject", "inter-subject"],
            addClassAfterCheck: "open"
        },
        intraSubject: {
            show: true,
            check: true,
            checkByName: "currentView",
            checkValue: "intra-subject",
            label: "Intra-Subject Impact Analysis",
            addClassAfterCheck: "active",
            redirectUrl: "/teacher/student/intra-subject/{studentUUId}/{subjectId}/{studentId}",
            redirectParams: ['studentUUId', 'subjectId', "studentId"]
        },
        interSubject: {
            show: true,
            check: true,
            checkByName: "currentView",
            checkValue: "inter-subject",
            label: "Inter-Subject Impact Analysis",
            addClassAfterCheck: "active",
            redirectUrl: "/teacher/student/inter-subject/{studentUUId}/{subjectId}/{studentId}",
            redirectParams: ['studentUUId', 'subjectId', "studentId"]
        },
        classProfile: {
            show: false
        },
        content: {
            show: false,
            check: true,
            checkByName: "currentView",
            checkValue: "content",
            permissionKey: "Content",
            label: "Content",
            addClassAfterCheck: "active",
            redirectUrl: '/teacher/student/subject/content/{studentUUId}/{subjectId}',
            redirectParams: ['subjectId', 'studentUUId']
        },
        personalisedPractice: {
            show: true,
            check: true,
            checkByName: "currentView",
            permissionKey: "PersonalisedPractice",
            checkValue: "personalisedPractice",
            label: "Personalised Practice",
            addClassAfterCheck: "active",
            redirectUrl: '/teacher/student/personalised-practice/{studentUUId}/{subjectId}',
            redirectParams: ['subjectId', 'studentUUId']
        },
        assignment: {
            show: true,
            check: true,
            checkByName: "currentView",
            defaultClass: '',
            permissionKey: "AssignmentGenerator",
            checkValue: "assignment",
            label: "Assignment",
            addClassAfterCheck: "active",
            redirectUrl: '/teacher/student/assignment/{studentUUId}/{subjectId}',
            redirectParams: ['subjectId', 'studentUUId']
        }
    },
    teacherClass: {
        addImageSectionInHeader: false,
        isClass: true,
        icon: {
            show: true,
            redirectUrl: '/teacher/class',
            redirectParams: []
        },
        overview: {
            show: true,
            check: true,
            checkByName: "currentView",
            checkValue: "overview",
            label: "Overview",
            addClassAfterCheck: "active",
            redirectUrl: '/teacher/class',
            redirectParams: []
        },
        skillImpact: {
            show: true,
            check: true,
            checkByName: "currentView",
            checkValue: ["intra-subject", "inter-subject"],
            addClassAfterCheck: "open"
        },
        intraSubject: {
            show: true,
            check: true,
            checkByName: "currentView",
            checkValue: "intra-subject",
            label: "Intra-Subject Impact Analysis",
            addClassAfterCheck: "active",
            redirectUrl: "/teacher/class/intra-subject",
            redirectParams: []
        },
        interSubject: {
            show: true,
            check: true,
            checkByName: "currentView",
            checkValue: "inter-subject",
            label: "Inter-Subject Impact Analysis",
            addClassAfterCheck: "active",
            redirectUrl: "/teacher/class/inter-subject",
            redirectParams: []
        },
        classProfile: {
            show: false
        },
        content: {
            show: true,
            check: true,
            checkByName: "currentView",
            checkValue: "content",
            permissionKey: "Content",
            label: "Content",
            addClassAfterCheck: "active",
            redirectUrl: '/teacher/class/content/{subjectId}',
            redirectParams: ['subjectId']
        },
        assignment: {
            show: true,
            check: true,
            checkByName: "currentView",
            checkValue: "assignment",
            permissionKey: "AssignmentGenerator",
            defaultClass: '',
            label: "Assignment Generator",
            addClassAfterCheck: "active",
            redirectUrl: '/teacher/class/assignment/{subjectId}',
            redirectParams: ['subjectId']
        }
        /* personalisedPractice: {
            show: true,
            check: true,
            checkByName: "currentView",
            checkValue: "personalisedPractice",
            addClassAfterCheck: "active",
            redirectUrl: '/teacher/class/personalised-practice/{subjectId}',
            redirectParams: ['subjectId']
        } */
    },
    teacherStudentContentDetail: {
        addImageSectionInHeader: false,
        isCustomContent: true,
        isClass: false,
        icon: {
            show: true
        },
        customText: {
            show: true,
            check: true,
            checkByName: "currentView",
            label: "Content",
            checkValue: "content"
        },
        goToPrevious: {
            show: false
        }
    },
    teacherClassContentDetail: {
        addImageSectionInHeader: false,
        isCustomContent: true,
        isClass: false,
        icon: {
            show: true
        },
        customText: {
            show: true,
            check: true,
            checkByName: "currentView",
            label: "Content",
            checkValue: "content"
        },
        goToPrevious: {
            show: false
        }
    },
    studentContentDetail: {
        addImageSectionInHeader: false,
        isCustomContent: true,
        isClass: false,
        icon: {
            show: true
        },
        customText: {
            show: true,
            check: true,
            checkByName: "currentView",
            label: "Content",
            checkValue: "content"
        },
        goToPrevious: {
            show: false
        }
    },
    studentPersonalised: {
        addImageSectionInHeader: false,
        isClass: false,
        isCustomContent: true,
        icon: {
            show: false
        },
        overview: {
            show: false,
            check: true,
            checkByName: "currentView",
            checkValue: "overview",
            label: "Overview",
            addClassAfterCheck: "active",
            redirectUrl: '/teacher/student/{studentUUId}/{subjectId}',
            redirectParams: ['studentUUId', 'subjectId']
        },
        skillImpact: {
            show: false,
            check: true,
            checkByName: "currentView",
            checkValue: ["intra-subject", "inter-subject"],
            addClassAfterCheck: "open"
        },
        intraSubject: {
            show: true,
            check: true,
            checkByName: "currentView",
            checkValue: "intra-subject",
            label: "Intra-Subject Impact Analysis",
            addClassAfterCheck: "active",
            redirectUrl: "/teacher/student/intra-subject/{studentUUId}/{subjectId}/{studentId}",
            redirectParams: ['studentUUId', 'subjectId', "studentId"]
        },
        interSubject: {
            show: true,
            check: true,
            checkByName: "currentView",
            checkValue: "inter-subject",
            label: "Inter-Subject Impact Analysis",
            addClassAfterCheck: "active",
            redirectUrl: "/teacher/student/inter-subject/{studentUUId}/{subjectId}/{studentId}",
            redirectParams: ['studentUUId', 'subjectId', "studentId"]
        },
        classProfile: {
            show: false
        },
        content: {
            show: false,
            check: true,
            checkByName: "currentView",
            checkValue: "content",
            permissionKey: "Content",
            label: "Content",
            addClassAfterCheck: "active",
            redirectUrl: '/teacher/student/subject/content/{studentUUId}/{subjectId}',
            redirectParams: ['subjectId', 'studentUUId']
        },
        personalisedPractice: {
            show: false,
            check: true,
            permissionKey: "PersonalisedPractice",
            checkByName: "currentView",
            checkValue: "personalisedPractice",
            label: "Personalised Practice",
            addClassAfterCheck: "active",
            redirectUrl: '/teacher/student/personalised-practice/{studentUUId}/{subjectId}',
            redirectParams: ['subjectId', 'studentUUId']
        }
    },
    teacherStudentPersonalised: {
        addImageSectionInHeader: true,
        isClass: false,
        icon: {
            show: false
        },
        overview: {
            show: true,
            check: true,
            checkByName: "currentView",
            checkValue: "overview",
            label: "Overview",
            addClassAfterCheck: "active",
            redirectUrl: '/teacher/student/{studentUUId}/{subjectId}',
            redirectParams: ['studentUUId', 'subjectId']
        },
        skillImpact: {
            show: true,
            check: true,
            checkByName: "currentView",
            checkValue: ["intra-subject", "inter-subject"],
            addClassAfterCheck: "open"
        },
        intraSubject: {
            show: true,
            check: true,
            checkByName: "currentView",
            checkValue: "intra-subject",
            label: "Intra-Subject Impact Analysis",
            addClassAfterCheck: "active",
            redirectUrl: "/teacher/student/intra-subject/{studentUUId}/{subjectId}/{studentId}",
            redirectParams: ['studentUUId', 'subjectId', "studentId"]
        },
        interSubject: {
            show: true,
            check: true,
            checkByName: "currentView",
            checkValue: "inter-subject",
            label: "Inter-Subject Impact Analysis",
            addClassAfterCheck: "active",
            redirectUrl: "/teacher/student/inter-subject/{studentUUId}/{subjectId}/{studentId}",
            redirectParams: ['studentUUId', 'subjectId', "studentId"]
        },
        classProfile: {
            show: false
        },
        content: {
            show: false,
            check: true,
            checkByName: "currentView",
            checkValue: "content",
            permissionKey: "Content",
            label: "Content",
            addClassAfterCheck: "active",
            redirectUrl: '/teacher/student/subject/content/{studentUUId}/{subjectId}',
            redirectParams: ['subjectId', 'studentUUId']
        },
        assignment: {
            show: true,
            check: true,
            checkByName: "currentView",
            checkValue: "assignment",
            permissionKey: "Assignment",
            defaultClass: '',
            label: "Assignment",
            addClassAfterCheck: "active",
            redirectUrl: '/teacher/student/assignment/{studentUUId}/{subjectId}',
            redirectParams: ['subjectId', 'studentUUId']
        }
        /* personalisedPractice: {
            show: true,
            check: true,
            checkByName: "currentView",
            checkValue: "personalisedPractice",
            addClassAfterCheck: "active",
            redirectUrl: '/teacher/student/personalised-practice/{studentUUId}/{subjectId}',
            redirectParams: ['subjectId', 'studentUUId']
        } */
    },
    parentPersonalisedPracticeDetail: {
        addImageSectionInHeader: false,
        isCustomContent: true,
        isClass: false,
        icon: {
            show: true
        },
        customText: {
            show: true,
            check: true,
            checkByName: "currentView",
            label: "Content",
            checkValue: "content"
        },
        goToPrevious: {
            show: false
        }
    },
    parentPersonalisedPracticeQuestionDetail: {
        addImageSectionInHeader: false,
        isCustomContent: true,
        isClass: false,
        icon: {
            show: true
        },
        customText: {
            show: true,
            check: true,
            checkByName: "currentView",
            label: "Content",
            checkValue: "content"
        },
        goToPrevious: {
            show: false
        }
    },
    studentPersonalisedPracticeDetail: {
        addImageSectionInHeader: false,
        isCustomContent: true,
        isClass: false,
        icon: {
            show: true
        },
        customText: {
            show: true,
            check: true,
            checkByName: "currentView",
            label: "Content",
            checkValue: "content"
        },
        goToPrevious: {
            show: false
        }
    },
    parentAssignmentDetail: {
        addImageSectionInHeader: false,
        isCustomContent: true,
        isClass: false,
        permissionKey: "Assignment",
        icon: {
            show: true
        },
        customText: {
            show: true,
            check: true,
            checkByName: "currentView",
            label: "Content",
            checkValue: "content"
        },
        goToPrevious: {
            show: false
        }
    },
    parentAssignmentQuestionDetail: {
        addImageSectionInHeader: false,
        isCustomContent: true,
        isClass: false,
        permissionKey: "Assignment",
        icon: {
            show: true
        },
        customText: {
            show: true,
            check: true,
            checkByName: "currentView",
            label: "Content",
            checkValue: "content"
        },
        goToPrevious: {
            show: false
        }
    },
    studentAssignmentDetail: {
        addImageSectionInHeader: false,
        isCustomContent: true,
        isClass: false,
        permissionKey: "Assignment",
        icon: {
            show: true
        },
        customText: {
            show: true,
            check: true,
            checkByName: "currentView",
            label: "Content",
            checkValue: "content"
        },
        goToPrevious: {
            show: false
        }
    },
    studentAttemptQuestion: {
        addImageSectionInHeader: false,
        isCustomContent: true,
        isClass: false,
        icon: {
            show: true
        },
        customText: {
            show: true,
            check: true,
            checkByName: "currentView",
            label: "Content",
            checkValue: "content"
        },
        goToPrevious: {
            show: false
        }
    },
    studentPersonalisedPracticeQuestionDetail: {
        addImageSectionInHeader: false,
        isCustomContent: true,
        isClass: false,
        icon: {
            show: true
        },
        customText: {
            show: true,
            check: true,
            checkByName: "currentView",
            label: "Content",
            checkValue: "content"
        },
        goToPrevious: {
            show: false
        }
    },
    teacherPersonalisedPracticeDetail: {
        addImageSectionInHeader: false,
        isCustomContent: true,
        isClass: false,
        icon: {
            show: true
        },
        customText: {
            show: true,
            check: true,
            checkByName: "currentView",
            label: "Content",
            checkValue: "content"
        },
        goToPrevious: {
            show: false
        }
    },
    teacherClassPersonalisedPracticeDetail: {
        addImageSectionInHeader: false,
        isCustomContent: true,
        isClass: true,
        icon: {
            show: true
        },
        customText: {
            show: true,
            check: true,
            checkByName: "currentView",
            label: "Content",
            checkValue: "content"
        },
        goToPrevious: {
            show: false
        }
    },
    teacherClassPersonalisedPracticeQuestionDetail: {
        addImageSectionInHeader: false,
        isCustomContent: true,
        isClass: true,
        icon: {
            show: false
        },
        customText: {
            show: true,
            check: true,
            checkByName: "currentView",
            label: "Content",
            checkValue: "content"
        },
        goToPrevious: {
            show: false
        }
    },
    teacherPersonalisedPracticeQuestionDetail: {
        addImageSectionInHeader: false,
        isCustomContent: true,
        isClass: false,
        icon: {
            show: false
        },
        customText: {
            show: true,
            check: true,
            checkByName: "currentView",
            label: "Content",
            checkValue: "content"
        },
        goToPrevious: {
            show: false
        }
    },
    studentAssignmentQuestionDetail: {
        addImageSectionInHeader: false,
        isCustomContent: true,
        isClass: false,
        permissionKey: "Assignment",
        icon: {
            show: true
        },
        customText: {
            show: true,
            check: true,
            checkByName: "currentView",
            label: "Content",
            checkValue: "content"
        },
        goToPrevious: {
            show: false
        }
    },
    teacherAssignmentDetail: {
        addImageSectionInHeader: false,
        isCustomContent: true,
        isClass: false,
        permissionKey: "Assignment",
        icon: {
            show: true
        },
        customText: {
            show: true,
            check: true,
            checkByName: "currentView",
            label: "Content",
            checkValue: "content"
        },
        goToPrevious: {
            show: false
        }
    },
    teacherClassAssignmentDetail: {
        addImageSectionInHeader: false,
        isCustomContent: true,
        permissionKey: "AssignmentGenerator",
        isClass: true,
        icon: {
            show: true
        },
        customText: {
            show: true,
            check: true,
            checkByName: "currentView",
            label: "Content",
            checkValue: "content"
        },
        goToPrevious: {
            show: false
        }
    },
    teacherClassAssignmentQuestionDetail: {
        addImageSectionInHeader: false,
        isCustomContent: true,
        permissionKey: "AssignmentGenerator",
        isClass: true,
        icon: {
            show: false
        },
        customText: {
            show: true,
            check: true,
            checkByName: "currentView",
            label: "Content",
            checkValue: "content"
        },
        goToPrevious: {
            show: false
        }
    },
    teacherAssignmentQuestionDetail: {
        addImageSectionInHeader: false,
        isCustomContent: true,
        permissionKey: "Assignment",
        isClass: false,
        icon: {
            show: false
        },
        customText: {
            show: true,
            check: true,
            checkByName: "currentView",
            label: "Content",
            checkValue: "content"
        },
        goToPrevious: {
            show: false
        }
    }
}