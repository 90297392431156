import { roleConstants } from '_constants';
import { serviceParams, services } from "_enums";
import { authHeader, errorHandler } from "_helpers";
import { env } from "environment";
import axios from "axios";
import { SpinnerService } from "./spinner.service";

//function to get user info 
export const getUserInfo = async (user: any) => {
    SpinnerService.sendMessage(true);
    let service: any = services, serviceParam: any = serviceParams;
    let serviceURL = '';
    let serviceName = '';
    let envURL = env.userManagementUrl;
    if (user.UserRole === roleConstants.STUDENT) {
        const responseData = await axios.get(`${env.userManagementUrl + service['studentProfile']}`, authHeader());
        user = { ...responseData.data.response, ...user };
        envURL = env.serviceBaseUrl;
        serviceName = 'studentInfo';
    } else if (user.UserRole === roleConstants.PARENT) {
        const responseData = await axios.get(`${env.userManagementUrl + service['parentProfile']}`, authHeader());
        user = { ...responseData.data.response, ...user };
        envURL = env.serviceBaseUrl;
        serviceName = 'parentInfo';
    } else if (user.UserRole === roleConstants.SCHOOLADMIN) {
        serviceName = 'staffInfo';
    } else if (user.UserRole === roleConstants.TEACHER) {
        const responseData = await axios.get(`${env.userManagementUrl + service['staffInfo']}`, authHeader());
        user = { ...responseData.data.response, ...user };
        envURL = env.serviceBaseUrl;
        serviceName = 'teachersInfo';
    } else if (user.UserRole === roleConstants.PRINCIPAL) {
        const responseData = await axios.get(`${env.userManagementUrl + service['staffInfo']}`, authHeader());
        user = { ...responseData.data.response, ...user };
        envURL = env.serviceBaseUrl;
        serviceName = 'principalInfo';
    } else if (user.UserRole === roleConstants.SUPERADMIN) {
        serviceName = 'staffInfo';
    }
    if (serviceName) {
        serviceURL = service[serviceName];
        if (serviceParam[serviceName] && serviceParam[serviceName].length > 0) {
            serviceParam[serviceName].forEach((param: string) => {
                serviceURL = serviceURL.split('{' + param + '}').join(user[param]);
            })
        }
    }
    let permissionData: any = {};
    let siteConfig: any = {};
    user.Fullname = user.Fullname ? user.Fullname : user.firstname;
    return axios.get(`${envURL + serviceURL}`, authHeader()).then(async(response) => {
        let classId=null;
        let subjectId=null;
        if (user.UserRole === roleConstants.STUDENT){
            classId=response.data.classId;
        }else if(user.UserRole === roleConstants.TEACHER){
            if(response.data.classes && response.data.classes.length>0){
                classId=response.data.classes[0].classId;
                if(response.data.classes[0].subjects && response.data.classes[0].subjects.length>0){
                    subjectId=response.data.classes[0].subjects[0].subjectId;
                }
            }
        }else if(user.UserRole === roleConstants.PARENT){
            if(response.data.children && response.data.children.length>0){
                classId=response.data.children[0].classId;
            }
        }
        const settingRes = await axios.get(`${env.serviceBaseUrl + service.permission}?${classId?'classId='+classId:''}${subjectId?'&subjectId='+subjectId:''}`, authHeader());
        if (settingRes.status === 200 && settingRes.data) {
            if (settingRes.data.permission && Array.isArray(settingRes.data.permission) && settingRes.data.permission.length > 0) {
                settingRes.data.permission.forEach((data: any) => {
                    permissionData[data.component] = data.permissions;
                });
            }
            if (settingRes.data.config) {
                siteConfig = settingRes.data.config;
            }

        }
        SpinnerService.sendMessage(false);
        if (user.UserRole === roleConstants.STUDENT || user.UserRole === roleConstants.PARENT || user.UserRole === roleConstants.TEACHER) {
            return { ...{ data: response.data }, ...{ userInfo: user, loginData: { Fullname: user.Fullname, permission: permissionData, siteConfig: siteConfig } } };
        } else if (user.UserRole === roleConstants.SCHOOLADMIN) {
            return { ...{ data: user }, ...{ userInfo: response.data.response, loginData: { Fullname: user.Fullname, permission: permissionData, siteConfig: siteConfig } } };
        } else if (user.UserRole === roleConstants.SUPERADMIN) {
            let standardDetail: [] = []
            return axios.get(`${envURL + service.getSuperAdminSubjectList}`).then((res) => {
                standardDetail = res.data.response;
                return { ...{ data: { user, classes: standardDetail } }, ...{ userInfo: response.data.response, loginData: { Fullname: user.Fullname, permission: permissionData, siteConfig: siteConfig } } };
            }).catch(err => errorHandler(err.response.message, err.response.status));

        } else {
            return { ...{ data: response.data }, ...{ userInfo: user, loginData: { Fullname: user.Fullname, permission: permissionData, siteConfig: siteConfig } } };
        }
    }).catch((error) => {
        SpinnerService.sendMessage(false);
        errorHandler(error.response.message, error.response.status);
        return Promise.reject(error)
    })
}
