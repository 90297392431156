import axios from "axios";
import { services } from "_enums";


export const addExam = (data: any) => {
    return axios.post(`${services.addExams}`, data);
}

export const submitExamMarks = (data: any,params:any) => {
    let url:string=services.assignmentMarks;
    Object.keys(params).forEach((data: any) => {
		url = url.split('{' + data + '}').join(params[data]);
	});
    return axios.put(`${url}`, data);
}

export const addExaminationQues = (data:any,params:any) => {
    let url:string=services.getAddExaminationQues;
    Object.keys(params).forEach((data: any) => {
		url = url.split('{' + data + '}').join(params[data]);
	});
    return axios.post(`${url}`, data);
}

export const updateExaminationQues = (data:any,params:any) => {
    let url:string=services.getAddExaminationQues;
    Object.keys(params).forEach((data: any) => {
		url = url.split('{' + data + '}').join(params[data]);
	});
    return axios.put(`${url}`, data);
}



