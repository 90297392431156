import { examConstants } from "_constants";
export const examActions = {
    assignDatasheetData
  };
function assignDatasheetData(datasheet:any){
    const request = (datasheet: any) => {
        return { type: examConstants.SET_DATASHEET_RECORD, data:datasheet };
      };
    return (dispatch: any) => {
        dispatch(request({ datasheet }));
    }
}