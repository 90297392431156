import axios from "axios";
import { services } from "_enums";

export const getAllAssignment = (data: any) => {
    return axios.get(`${services.allAssigments}/${data.classId}`, {
        params: data
    }).then((response) => {
        return response.data
    }).catch((error) => {
        return Promise.reject(error)
    })
}

export const addAssignment = (data: any) => {
    return axios.post(`${services.addAssignment}`, data);
}

export const submitAssignmentMarks = (data: any,params:any) => {
    let url:string=services.assignmentMarks;
    Object.keys(params).forEach((data: any) => {
		url = url.split('{' + data + '}').join(params[data]);
	});
    return axios.put(`${url}`, data);
}



